export let softs = [
 {
  "id": "1",
  "ini": "と",
  "name": "ドンキーコング",
  "maker": "任天堂",
  "releaseDate": "1983/7/15",
  "price": "3800",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "2",
  "ini": "と",
  "name": "ドンキーコングＪｒ．",
  "maker": "任天堂",
  "releaseDate": "1983/7/15",
  "price": "3800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "3",
  "ini": "ほ",
  "name": "ポパイ",
  "maker": "任天堂",
  "releaseDate": "1983/7/15",
  "price": "3800",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "4",
  "ini": "こ",
  "name": "五目ならべ 連珠",
  "maker": "任天堂",
  "releaseDate": "1983/8/27",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "ま",
  "name": "麻雀",
  "maker": "任天堂",
  "releaseDate": "1983/8/27",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "6",
  "ini": "ま",
  "name": "マリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "1983/9/9",
  "price": "3800",
  "genre": "アクション",
  "icNo": "20"
 },
 {
  "id": "7",
  "ini": "ほ",
  "name": "ポパイの英語遊び",
  "maker": "任天堂",
  "releaseDate": "1983/11/22",
  "price": "3800",
  "genre": "学習・アクション",
  "icNo": "30"
 },
 {
  "id": "8",
  "ini": "へ",
  "name": "ベースボール",
  "maker": "任天堂",
  "releaseDate": "1983/12/7",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "50"
 },
 {
  "id": "9",
  "ini": "と",
  "name": "ドンキーコングＪｒ．の算数遊び",
  "maker": "任天堂",
  "releaseDate": "1983/12/12",
  "price": "3800",
  "genre": "学習・アクション",
  "icNo": "53"
 },
 {
  "id": "10",
  "ini": "て",
  "name": "テニス",
  "maker": "任天堂",
  "releaseDate": "1984/1/14",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "40"
 },
 {
  "id": "11",
  "ini": "ひ",
  "name": "ピンボール",
  "maker": "任天堂",
  "releaseDate": "1984/2/2",
  "price": "3800",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "12",
  "ini": "わ",
  "name": "ワイルドガンマン（光線銃シリーズ）",
  "maker": "任天堂",
  "releaseDate": "1984/2/18",
  "price": "3800",
  "genre": "ガンシューティング",
  "icNo": "40"
 },
 {
  "id": "13",
  "ini": "た",
  "name": "ダックハント（光線銃シリーズ）",
  "maker": "任天堂",
  "releaseDate": "1984/4/21",
  "price": "3800",
  "genre": "ガンシューティング",
  "icNo": "40"
 },
 {
  "id": "14",
  "ini": "こ",
  "name": "ゴルフ",
  "maker": "任天堂",
  "releaseDate": "1984/5/1",
  "price": "3800",
  "genre": "スポーツ",
  "icNo": "56"
 },
 {
  "id": "15",
  "ini": "ほ",
  "name": "ホーガンズアレイ（光線銃シリーズ）",
  "maker": "任天堂",
  "releaseDate": "1984/6/12",
  "price": "3800",
  "genre": "ガンシューティング",
  "icNo": "40"
 },
 {
  "id": "16",
  "ini": "ふ",
  "name": "ファミリーベーシック",
  "maker": "任天堂",
  "releaseDate": "1984/6/21",
  "price": "14800",
  "genre": "プログラミング",
  "icNo": "91"
 },
 {
  "id": "17",
  "ini": "と",
  "name": "ドンキーコング３",
  "maker": "任天堂",
  "releaseDate": "1984/7/4",
  "price": "3800",
  "genre": "アクション・シューティング",
  "icNo": "10"
 },
 {
  "id": "18",
  "ini": "な",
  "name": "ナッツ＆ミルク",
  "maker": "ハドソン",
  "releaseDate": "1984/7/28",
  "price": "4500",
  "genre": "アクション・パズル",
  "icNo": "53"
 },
 {
  "id": "19",
  "ini": "ろ",
  "name": "ロードランナー",
  "maker": "ハドソン",
  "releaseDate": "1984/7/31",
  "price": "4500",
  "genre": "アクション・パズル",
  "icNo": "30"
 },
 {
  "id": "20",
  "ini": "き",
  "name": "ギャラクシアン",
  "maker": "ナムコ",
  "releaseDate": "1984/9/7",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "て",
  "name": "デビルワールド",
  "maker": "任天堂",
  "releaseDate": "1984/10/5",
  "price": "4500",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "22",
  "ini": "よ",
  "name": "４人打ち麻雀",
  "maker": "任天堂",
  "releaseDate": "1984/11/2",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "61"
 },
 {
  "id": "23",
  "ini": "え",
  "name": "Ｆ１レース",
  "maker": "任天堂",
  "releaseDate": "1984/11/2",
  "price": "4500",
  "genre": "3D・レース",
  "icNo": "42"
 },
 {
  "id": "24",
  "ini": "は",
  "name": "パックマン",
  "maker": "ナムコ",
  "releaseDate": "1984/11/2",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "せ",
  "name": "ゼビウス",
  "maker": "ナムコ",
  "releaseDate": "1984/11/8",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "あ",
  "name": "アーバンチャンピオン",
  "maker": "任天堂",
  "releaseDate": "1984/11/14",
  "price": "4500",
  "genre": "格闘・アクション",
  "icNo": "53"
 },
 {
  "id": "27",
  "ini": "ま",
  "name": "マッピー",
  "maker": "ナムコ",
  "releaseDate": "1984/11/14",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "く",
  "name": "クルクルランド",
  "maker": "任天堂",
  "releaseDate": "1984/11/22",
  "price": "4500",
  "genre": "アクション・パズル",
  "icNo": "20"
 },
 {
  "id": "29",
  "ini": "え",
  "name": "エキサイトバイク",
  "maker": "任天堂",
  "releaseDate": "1984/11/30",
  "price": "5500",
  "genre": "レース",
  "icNo": "10"
 },
 {
  "id": "30",
  "ini": "は",
  "name": "バルーンファイト",
  "maker": "任天堂",
  "releaseDate": "1985/1/22",
  "price": "4500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "31",
  "ini": "あ",
  "name": "アイスクライマー",
  "maker": "任天堂",
  "releaseDate": "1985/1/30",
  "price": "4500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "32",
  "ini": "え",
  "name": "エクセリオン",
  "maker": "ジャレコ",
  "releaseDate": "1985/2/11",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "54"
 },
 {
  "id": "33",
  "ini": "き",
  "name": "ギャラガ",
  "maker": "ナムコ",
  "releaseDate": "1985/2/15",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "ふ",
  "name": "ファミリーベーシックV3",
  "maker": "任天堂",
  "releaseDate": "1985/2/21",
  "price": "9800",
  "genre": "プログラミング",
  "icNo": "93"
 },
 {
  "id": "35",
  "ini": "は",
  "name": "バンゲリングベイ",
  "maker": "ハドソン",
  "releaseDate": "1985/2/22",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "ふ",
  "name": "フォーメーションＺ",
  "maker": "ジャレコ",
  "releaseDate": "1985/4/4",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "20"
 },
 {
  "id": "37",
  "ini": "さ",
  "name": "サッカー",
  "maker": "任天堂",
  "releaseDate": "1985/4/9",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "60"
 },
 {
  "id": "38",
  "ini": "す",
  "name": "スペースインベーダー",
  "maker": "タイトー",
  "releaseDate": "1985/4/17",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "ち",
  "name": "チャンピオンシップロードランナー",
  "maker": "ハドソン",
  "releaseDate": "1985/4/17",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "0"
 },
 {
  "id": "40",
  "ini": "い",
  "name": "イーアルカンフー",
  "maker": "コナミ",
  "releaseDate": "1985/4/22",
  "price": "4500",
  "genre": "格闘・アクション",
  "icNo": "1"
 },
 {
  "id": "41",
  "ini": "け",
  "name": "けっきょく南極大冒険",
  "maker": "コナミ",
  "releaseDate": "1985/4/22",
  "price": "4500",
  "genre": "3D・レース",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "に",
  "name": "忍者くん 魔城の冒険",
  "maker": "ジャレコ",
  "releaseDate": "1985/5/10",
  "price": "4500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "43",
  "ini": "ち",
  "name": "ちゃっくんぽっぷ",
  "maker": "タイトー",
  "releaseDate": "1985/5/24",
  "price": "4500",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "て",
  "name": "ディグダグ",
  "maker": "ナムコ",
  "releaseDate": "1985/6/4",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "ふ",
  "name": "フラッピー",
  "maker": "デービーソフト",
  "releaseDate": "1985/6/14",
  "price": "5500",
  "genre": "アクション・パズル",
  "icNo": "30"
 },
 {
  "id": "46",
  "ini": "れ",
  "name": "レッキングクルー",
  "maker": "任天堂",
  "releaseDate": "1985/6/18",
  "price": "5500",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "47",
  "ini": "す",
  "name": "スパルタンＸ",
  "maker": "任天堂",
  "releaseDate": "1985/6/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "48",
  "ini": "は",
  "name": "ハイパーオリンピック",
  "maker": "コナミ",
  "releaseDate": "1985/6/21",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "す",
  "name": "スターフォース",
  "maker": "ハドソン",
  "releaseDate": "1985/6/25",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "52"
 },
 {
  "id": "50",
  "ini": "え",
  "name": "エレベーターアクション",
  "maker": "タイトー",
  "releaseDate": "1985/6/28",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "ふ",
  "name": "フィールドコンバット",
  "maker": "ジャレコ",
  "releaseDate": "1985/7/9",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "61"
 },
 {
  "id": "52",
  "ini": "ろ",
  "name": "ロードファイター",
  "maker": "コナミ",
  "releaseDate": "1985/7/11",
  "price": "4500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "わ",
  "name": "ワープマン",
  "maker": "ナムコ",
  "releaseDate": "1985/7/12",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "し",
  "name": "ジッピーレース",
  "maker": "アイレム",
  "releaseDate": "1985/7/18",
  "price": "4500",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "55",
  "ini": "と",
  "name": "ドアドア",
  "maker": "エニックス",
  "releaseDate": "1985/7/18",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "す",
  "name": "スーパーアラビアン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1985/7/25",
  "price": "4500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "57",
  "ini": "ふ",
  "name": "ブロックセット（ファミリーコンピュータロボット）",
  "maker": "任天堂",
  "releaseDate": "1985/7/26",
  "price": "4800",
  "genre": "ロボット操作",
  "icNo": "0"
 },
 {
  "id": "58",
  "ini": "ふ",
  "name": "フロントライン",
  "maker": "タイトー",
  "releaseDate": "1985/8/1",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "と",
  "name": "ドルアーガの塔",
  "maker": "ナムコ",
  "releaseDate": "1985/8/6",
  "price": "4900",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "あ",
  "name": "アストロロボ SASA",
  "maker": "アスキー",
  "releaseDate": "1985/8/9",
  "price": "5500",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "61",
  "ini": "ほ",
  "name": "本将棋 内藤九段将棋秘伝",
  "maker": "セタ",
  "releaseDate": "1985/8/10",
  "price": "4500",
  "genre": "テーブル",
  "icNo": "20"
 },
 {
  "id": "62",
  "ini": "し",
  "name": "ジャイロセット（ファミリーコンピュータロボット）",
  "maker": "任天堂",
  "releaseDate": "1985/8/13",
  "price": "5800",
  "genre": "ロボット操作",
  "icNo": "0"
 },
 {
  "id": "63",
  "ini": "け",
  "name": "ゲイモス",
  "maker": "アスキー",
  "releaseDate": "1985/8/28",
  "price": "5500",
  "genre": "3D・シューティング",
  "icNo": "2"
 },
 {
  "id": "64",
  "ini": "て",
  "name": "１０ヤードファイト",
  "maker": "アイレム",
  "releaseDate": "1985/8/30",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "65",
  "ini": "は",
  "name": "バトルシティー",
  "maker": "ナムコ",
  "releaseDate": "1985/9/9",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "す",
  "name": "スーパーマリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "1985/9/13",
  "price": "4900",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "67",
  "ini": "ふ",
  "name": "プーヤン",
  "maker": "ハドソン",
  "releaseDate": "1985/9/20",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "68",
  "ini": "は",
  "name": "ハイパースポーツ",
  "maker": "コナミ",
  "releaseDate": "1985/9/27",
  "price": "4500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "69",
  "ini": "し",
  "name": "シティコネクション",
  "maker": "ジャレコ",
  "releaseDate": "1985/9/27",
  "price": "4900",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "70",
  "ini": "る",
  "name": "ルート16ターボ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1985/10/4",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "71",
  "ini": "ち",
  "name": "チャレンジャー",
  "maker": "ハドソン",
  "releaseDate": "1985/10/15",
  "price": "4900",
  "genre": "アクション",
  "icNo": "34"
 },
 {
  "id": "72",
  "ini": "き",
  "name": "キン肉マン マッスルタッグマッチ",
  "maker": "バンダイ",
  "releaseDate": "1985/11/8",
  "price": "4900",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "73",
  "ini": "す",
  "name": "スカイデストロイヤー",
  "maker": "タイトー",
  "releaseDate": "1985/11/14",
  "price": "4500",
  "genre": "3D・シューティング",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "に",
  "name": "忍者じゃじゃ丸くん",
  "maker": "ジャレコ",
  "releaseDate": "1985/11/15",
  "price": "4900",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "75",
  "ini": "お",
  "name": "おにゃんこＴＯＷＮ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1985/11/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "76",
  "ini": "は",
  "name": "パチコン",
  "maker": "東芝EMI",
  "releaseDate": "1985/11/21",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "77",
  "ini": "は",
  "name": "パックランド",
  "maker": "ナムコ",
  "releaseDate": "1985/11/21",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "ま",
  "name": "マッハライダー",
  "maker": "任天堂",
  "releaseDate": "1985/11/21",
  "price": "4900",
  "genre": "3Dレース",
  "icNo": "52"
 },
 {
  "id": "79",
  "ini": "は",
  "name": "バーガータイム",
  "maker": "ナムコ",
  "releaseDate": "1985/11/27",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "い",
  "name": "いっき",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1985/11/28",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "81",
  "ini": "ほ",
  "name": "ポートピア連続殺人事件",
  "maker": "エニックス",
  "releaseDate": "1985/11/29",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "か",
  "name": "カラテカ",
  "maker": "ソフトプロ",
  "releaseDate": "1985/12/5",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "る",
  "name": "ルナーボール",
  "maker": "ポニーキャニオン",
  "releaseDate": "1985/12/5",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "84",
  "ini": "す",
  "name": "スターラスター",
  "maker": "ナムコ",
  "releaseDate": "1985/12/6",
  "price": "4900",
  "genre": "3Dシューティング",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "す",
  "name": "スペランカー",
  "maker": "アイレム",
  "releaseDate": "1985/12/7",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "86",
  "ini": "こ",
  "name": "高機動戦闘メカ ヴォルガードⅡ",
  "maker": "デービーソフト",
  "releaseDate": "1985/12/7",
  "price": "4900",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "87",
  "ini": "ち",
  "name": "超時空要塞マクロス",
  "maker": "バンダイ",
  "releaseDate": "1985/12/10",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "い",
  "name": "1942",
  "maker": "カプコン",
  "releaseDate": "1985/12/11",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "52"
 },
 {
  "id": "89",
  "ini": "た",
  "name": "ダウ・ボーイ",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1985/12/11",
  "price": "5300",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "90",
  "ini": "ほ",
  "name": "ボコスカウォーズ",
  "maker": "アスキー",
  "releaseDate": "1985/12/14",
  "price": "5500",
  "genre": "シュミレーション・RPG",
  "icNo": "64"
 },
 {
  "id": "91",
  "ini": "す",
  "name": "頭脳戦艦ガル",
  "maker": "デービーソフト",
  "releaseDate": "1985/12/14",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "92",
  "ini": "お",
  "name": "オバケのＱ太郎 ワンワンパニック",
  "maker": "バンダイ",
  "releaseDate": "1985/12/16",
  "price": "4900",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "93",
  "ini": "て",
  "name": "テグザー",
  "maker": "スクウェア",
  "releaseDate": "1985/12/19",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "0"
 },
 {
  "id": "94",
  "ini": "は",
  "name": "バイナリィランド",
  "maker": "ハドソン",
  "releaseDate": "1985/12/19",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "13"
 },
 {
  "id": "95",
  "ini": "ほ",
  "name": "ボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "1985/12/19",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "96",
  "ini": "え",
  "name": "エグゼドエグゼス",
  "maker": "徳間書店",
  "releaseDate": "1985/12/21",
  "price": "5200",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "97",
  "ini": "ろ",
  "name": "ロットロット",
  "maker": "徳間書店",
  "releaseDate": "1985/12/21",
  "price": "5200",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "98",
  "ini": "へ",
  "name": "ぺんぎんくんWARS",
  "maker": "アスキー",
  "releaseDate": "1985/12/25",
  "price": "5500",
  "genre": "3D・アクション",
  "icNo": "22"
 },
 {
  "id": "99",
  "ini": "つ",
  "name": "ツインビー",
  "maker": "コナミ",
  "releaseDate": "1986/1/4",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "そ",
  "name": "ソンソン",
  "maker": "カプコン",
  "releaseDate": "1986/2/8",
  "price": "4900",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "101",
  "ini": "く",
  "name": "グーニーズ",
  "maker": "コナミ",
  "releaseDate": "1986/2/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "さ",
  "name": "サーカスチャーリー",
  "maker": "ソフトプロ",
  "releaseDate": "1986/3/4",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "に",
  "name": "忍者ハットリくん 忍者は修行でござるの巻",
  "maker": "ハドソン",
  "releaseDate": "1986/3/5",
  "price": "4900",
  "genre": "アクション",
  "icNo": "34"
 },
 {
  "id": "104",
  "ini": "し",
  "name": "ジャイロダイン",
  "maker": "タイトー",
  "releaseDate": "1986/3/13",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "は",
  "name": "ハイドライド・スペシャル",
  "maker": "東芝EMI",
  "releaseDate": "1986/3/18",
  "price": "4900",
  "genre": "アクション・RPG",
  "icNo": "0"
 },
 {
  "id": "106",
  "ini": "は",
  "name": "バルトロン",
  "maker": "東映動画",
  "releaseDate": "1986/3/19",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "ま",
  "name": "マグマックス",
  "maker": "日本物産",
  "releaseDate": "1986/3/19",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "108",
  "ini": "た",
  "name": "タッグチームプロレスリング",
  "maker": "ナムコ",
  "releaseDate": "1986/4/2",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "あ",
  "name": "アーガス",
  "maker": "ジャレコ",
  "releaseDate": "1986/4/17",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "53"
 },
 {
  "id": "110",
  "ini": "あ",
  "name": "アトランチスの謎",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1986/4/17",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "111",
  "ini": "け",
  "name": "ゲゲゲの鬼太郎 妖怪大魔境",
  "maker": "バンダイ",
  "releaseDate": "1986/4/17",
  "price": "4900",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "112",
  "ini": "か",
  "name": "影の伝説",
  "maker": "タイトー",
  "releaseDate": "1986/4/18",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "て",
  "name": "ディグダグⅡ",
  "maker": "ナムコ",
  "releaseDate": "1986/4/18",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "ま",
  "name": "マイティボンジャック",
  "maker": "テクモ",
  "releaseDate": "1986/4/24",
  "price": "4980",
  "genre": "アクション・パズル",
  "icNo": "30"
 },
 {
  "id": "115",
  "ini": "く",
  "name": "グラディウス",
  "maker": "コナミ",
  "releaseDate": "1986/4/25",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "け",
  "name": "けいさんゲーム さんすう1年",
  "maker": "東京書籍",
  "releaseDate": "1986/4/25",
  "price": "4900",
  "genre": "学習",
  "icNo": "54"
 },
 {
  "id": "117",
  "ini": "け",
  "name": "けいさんゲーム 算数2年",
  "maker": "東京書籍",
  "releaseDate": "1986/4/25",
  "price": "4900",
  "genre": "学習",
  "icNo": "54"
 },
 {
  "id": "118",
  "ini": "け",
  "name": "けいさんゲーム 算数3年",
  "maker": "東京書籍",
  "releaseDate": "1986/4/25",
  "price": "4900",
  "genre": "学習",
  "icNo": "54"
 },
 {
  "id": "119",
  "ini": "す",
  "name": "スパイVSスパイ",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1986/4/26",
  "price": "4900",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "120",
  "ini": "せ",
  "name": "セクロス",
  "maker": "日本物産",
  "releaseDate": "1986/5/19",
  "price": "4900",
  "genre": "レース・シューティング",
  "icNo": "0"
 },
 {
  "id": "121",
  "ini": "と",
  "name": "ドラゴンクエスト",
  "maker": "エニックス",
  "releaseDate": "1986/5/27",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "は",
  "name": "バード・ウィーク",
  "maker": "東芝EMI",
  "releaseDate": "1986/6/3",
  "price": "4900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "123",
  "ini": "ひ",
  "name": "B-ウイング",
  "maker": "データイースト",
  "releaseDate": "1986/6/3",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "124",
  "ini": "す",
  "name": "スターソルジャー",
  "maker": "ハドソン",
  "releaseDate": "1986/6/13",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "ま",
  "name": "魔界村",
  "maker": "カプコン",
  "releaseDate": "1986/6/13",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "す",
  "name": "スーパーチャイニーズ",
  "maker": "ナムコ",
  "releaseDate": "1986/6/20",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "す",
  "name": "スクーン",
  "maker": "アイレム",
  "releaseDate": "1986/6/26",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "128",
  "ini": "ち",
  "name": "チョップリフター",
  "maker": "ジャレコ",
  "releaseDate": "1986/6/26",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "54"
 },
 {
  "id": "129",
  "ini": "か",
  "name": "かんしゃく玉なげカン太郎の東海道五十三次",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1986/7/3",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "130",
  "ini": "は",
  "name": "バベルの塔",
  "maker": "ナムコ",
  "releaseDate": "1986/7/18",
  "price": "3900",
  "genre": "アクション・パズル",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "か",
  "name": "がんばれゴエモン！ からくり道中",
  "maker": "コナミ",
  "releaseDate": "1986/7/30",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "そ",
  "name": "ソロモンの鍵",
  "maker": "テクモ",
  "releaseDate": "1986/7/30",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "30"
 },
 {
  "id": "133",
  "ini": "わ",
  "name": "ワルキューレの冒険 時の鍵の伝説",
  "maker": "ナムコ",
  "releaseDate": "1986/8/1",
  "price": "3900",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "む",
  "name": "六三四の剣 ただいま修行中",
  "maker": "タイトー",
  "releaseDate": "1986/8/8",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "135",
  "ini": "ほ",
  "name": "北斗の拳",
  "maker": "東映動画",
  "releaseDate": "1986/8/10",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "136",
  "ini": "し",
  "name": "じゃじゃ丸の大冒険",
  "maker": "ジャレコ",
  "releaseDate": "1986/8/22",
  "price": "4900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "137",
  "ini": "す",
  "name": "スカイキッド",
  "maker": "ナムコ",
  "releaseDate": "1986/8/22",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "138",
  "ini": "き",
  "name": "機動戦士Zガンダム ホットスクランブル",
  "maker": "バンダイ",
  "releaseDate": "1986/8/28",
  "price": "5300",
  "genre": "3D・シューティング・アクション",
  "icNo": "4"
 },
 {
  "id": "139",
  "ini": "え",
  "name": "ASO",
  "maker": "SNK",
  "releaseDate": "1986/9/3",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "53"
 },
 {
  "id": "140",
  "ini": "す",
  "name": "スーパーピットフォール",
  "maker": "ポニーキャニオン",
  "releaseDate": "1986/9/5",
  "price": "5500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "141",
  "ini": "は",
  "name": "バナナ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1986/9/8",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "11"
 },
 {
  "id": "142",
  "ini": "た",
  "name": "高橋名人の冒険島",
  "maker": "ハドソン",
  "releaseDate": "1986/9/12",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "143",
  "ini": "き",
  "name": "キングスナイト",
  "maker": "スクウェア",
  "releaseDate": "1986/9/18",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "61"
 },
 {
  "id": "144",
  "ini": "す",
  "name": "スーパーゼビウス ガンプの謎",
  "maker": "ナムコ",
  "releaseDate": "1986/9/19",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "82"
 },
 {
  "id": "145",
  "ini": "こ",
  "name": "ゴーストバスターズ",
  "maker": "徳間書店",
  "releaseDate": "1986/9/22",
  "price": "4500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "146",
  "ini": "す",
  "name": "スペースハンター",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1986/9/26",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "51"
 },
 {
  "id": "147",
  "ini": "て",
  "name": "テラクレスタ",
  "maker": "日本物産",
  "releaseDate": "1986/9/27",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "148",
  "ini": "せ",
  "name": "戦場の狼",
  "maker": "カプコン",
  "releaseDate": "1986/9/27",
  "price": "5500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "149",
  "ini": "は",
  "name": "バギーポッパー",
  "maker": "データイースト",
  "releaseDate": "1986/10/8",
  "price": "4900",
  "genre": "レースアクション",
  "icNo": "61"
 },
 {
  "id": "150",
  "ini": "う",
  "name": "うる星やつら ラムのウェディングベル",
  "maker": "ジャレコ",
  "releaseDate": "1986/10/23",
  "price": "4900",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "151",
  "ini": "け",
  "name": "けいさんゲーム 算数4年",
  "maker": "東京書籍",
  "releaseDate": "1986/10/30",
  "price": "4900",
  "genre": "学習",
  "icNo": "54"
 },
 {
  "id": "152",
  "ini": "け",
  "name": "けいさんゲーム 算数5・6年",
  "maker": "東京書籍",
  "releaseDate": "1986/10/30",
  "price": "4900",
  "genre": "学習",
  "icNo": "54"
 },
 {
  "id": "153",
  "ini": "み",
  "name": "ミシシッピー殺人事件",
  "maker": "ジャレコ",
  "releaseDate": "1986/10/31",
  "price": "5200",
  "genre": "アドベンチャー",
  "icNo": "50"
 },
 {
  "id": "154",
  "ini": "す",
  "name": "スーパースターフォース 時空歴の秘密",
  "maker": "テクモ",
  "releaseDate": "1986/11/11",
  "price": "5300",
  "genre": "シューティング・アクション",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "あ",
  "name": "アスレチックワールド（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1986/11/12",
  "price": "8500",
  "genre": "ファミリートレーナー",
  "icNo": "99"
 },
 {
  "id": "156",
  "ini": "お",
  "name": "Othello",
  "maker": "河田",
  "releaseDate": "1986/11/13",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "40"
 },
 {
  "id": "157",
  "ini": "め",
  "name": "迷宮組曲 ミロンの大冒険",
  "maker": "ハドソン",
  "releaseDate": "1986/11/13",
  "price": "4900",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "158",
  "ini": "あ",
  "name": "アイギーナの予言",
  "maker": "ビック東海",
  "releaseDate": "1986/11/21",
  "price": "5300",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "159",
  "ini": "か",
  "name": "元祖西遊記 スーパーモンキー大冒険",
  "maker": "バップ",
  "releaseDate": "1986/11/21",
  "price": "4900",
  "genre": "アクション・RPG",
  "icNo": "64"
 },
 {
  "id": "160",
  "ini": "な",
  "name": "長靴をはいた猫 世界一周80日大冒険",
  "maker": "東映動画",
  "releaseDate": "1986/11/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "161",
  "ini": "ま",
  "name": "マッピーランド",
  "maker": "ナムコ",
  "releaseDate": "1986/11/26",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "162",
  "ini": "い",
  "name": "怒 IKARI",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1986/11/26",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "51"
 },
 {
  "id": "163",
  "ini": "と",
  "name": "ドラゴンボール 神龍の謎",
  "maker": "バンダイ",
  "releaseDate": "1986/11/27",
  "price": "5300",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "164",
  "ini": "き",
  "name": "キャッスルエクセレント",
  "maker": "アスキー",
  "releaseDate": "1986/11/28",
  "price": "4900",
  "genre": "アクション・パズル",
  "icNo": "0"
 },
 {
  "id": "165",
  "ini": "た",
  "name": "タイガーヘリ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1986/12/5",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "166",
  "ini": "て",
  "name": "ディーヴァ ナーサティアの玉座",
  "maker": "東芝EMI",
  "releaseDate": "1986/12/5",
  "price": "5500",
  "genre": "シューティング・シミュレーション",
  "icNo": "3"
 },
 {
  "id": "167",
  "ini": "た",
  "name": "トランスフォーマー コンボイの謎",
  "maker": "タカラ",
  "releaseDate": "1986/12/5",
  "price": "4900",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "168",
  "ini": "ほ",
  "name": "ホッターマンの地底探険",
  "maker": "ユース",
  "releaseDate": "1986/12/6",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "169",
  "ini": "た",
  "name": "たけしの挑戦状",
  "maker": "タイトー",
  "releaseDate": "1986/12/10",
  "price": "5300",
  "genre": "アクション・アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "ふ",
  "name": "プロ野球 ファミリースタジアム",
  "maker": "ナムコ",
  "releaseDate": "1986/12/10",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "し",
  "name": "シャーロックホームズ 伯爵令嬢誘拐事件",
  "maker": "トーワチキ",
  "releaseDate": "1986/12/11",
  "price": "5000",
  "genre": "アクション・アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "172",
  "ini": "と",
  "name": "ドラえもん",
  "maker": "ハドソン",
  "releaseDate": "1986/12/12",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "0"
 },
 {
  "id": "173",
  "ini": "ま",
  "name": "魔鐘",
  "maker": "アイレム",
  "releaseDate": "1986/12/15",
  "price": "5300",
  "genre": "アクションRPG",
  "icNo": "0"
 },
 {
  "id": "174",
  "ini": "め",
  "name": "メトロクロス",
  "maker": "ナムコ",
  "releaseDate": "1986/12/16",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "き",
  "name": "キングコング2 怒りのメガトンパンチ",
  "maker": "コナミ",
  "releaseDate": "1986/12/18",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "176",
  "ini": "ま",
  "name": "マドゥーラの翼",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1986/12/18",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "177",
  "ini": "れ",
  "name": "レイラ",
  "maker": "デービーソフト",
  "releaseDate": "1986/12/20",
  "price": "5300",
  "genre": "アクション・シューティング",
  "icNo": "64"
 },
 {
  "id": "178",
  "ini": "こ",
  "name": "コスモジェネシス",
  "maker": "アスキー",
  "releaseDate": "1986/12/23",
  "price": "4900",
  "genre": "3Dシューティング・シミュレーション",
  "icNo": "54"
 },
 {
  "id": "179",
  "ini": "ら",
  "name": "ランニングスタジアム（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1986/12/23",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "98"
 },
 {
  "id": "180",
  "ini": "た",
  "name": "闘いの挽歌",
  "maker": "カプコン",
  "releaseDate": "1986/12/24",
  "price": "5500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "181",
  "ini": "せ",
  "name": "聖飢魔Ⅱ 悪魔の逆襲！",
  "maker": "CBS・ソニー",
  "releaseDate": "1986/12/25",
  "price": "4900",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "182",
  "ini": "あ",
  "name": "アルカノイド",
  "maker": "タイトー",
  "releaseDate": "1986/12/26",
  "price": "5400",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "183",
  "ini": "く",
  "name": "クレイジークライマー",
  "maker": "日本物産",
  "releaseDate": "1986/12/26",
  "price": "5300",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "184",
  "ini": "と",
  "name": "時空の旅人",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1986/12/26",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "51"
 },
 {
  "id": "185",
  "ini": "ひ",
  "name": "火の鳥 鳳凰編 我王の冒険",
  "maker": "コナミ",
  "releaseDate": "1987/1/4",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "と",
  "name": "ドラゴンバスター",
  "maker": "ナムコ",
  "releaseDate": "1987/1/7",
  "price": "4900",
  "genre": "アクション",
  "icNo": "82"
 },
 {
  "id": "187",
  "ini": "ら",
  "name": "ラビリンス",
  "maker": "徳間書店",
  "releaseDate": "1987/1/7",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "188",
  "ini": "と",
  "name": "ドラゴンクエストⅡ 悪霊の神々",
  "maker": "エニックス",
  "releaseDate": "1987/1/26",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "さ",
  "name": "ザ・ブラックバス",
  "maker": "ホット・ビィ",
  "releaseDate": "1987/2/6",
  "price": "5300",
  "genre": "釣り",
  "icNo": "52"
 },
 {
  "id": "190",
  "ini": "し",
  "name": "新人類",
  "maker": "リコーエレメックス",
  "releaseDate": "1987/2/10",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "10"
 },
 {
  "id": "191",
  "ini": "ひ",
  "name": "飛龍の拳 奥義の書",
  "maker": "カルチャーブレーン",
  "releaseDate": "1987/2/14",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "192",
  "ini": "え",
  "name": "エアロビスタジオ（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1987/2/26",
  "price": "5800",
  "genre": "ファミリートレーナー",
  "icNo": "95"
 },
 {
  "id": "193",
  "ini": "ほ",
  "name": "ポケットザウルス 十王剣の謎",
  "maker": "バンダイ",
  "releaseDate": "1987/2/27",
  "price": "5500",
  "genre": "アクション・シューティング",
  "icNo": "32"
 },
 {
  "id": "194",
  "ini": "い",
  "name": "いきなりミュージシャン",
  "maker": "東京書籍",
  "releaseDate": "1987/3/5",
  "price": "4900",
  "genre": "演奏",
  "icNo": "30"
 },
 {
  "id": "195",
  "ini": "み",
  "name": "ミッキーマウス 不思議の国の大冒険",
  "maker": "ハドソン",
  "releaseDate": "1987/3/6",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "196",
  "ini": "ろ",
  "name": "Law of the West 西部の掟",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/3/6",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "34"
 },
 {
  "id": "197",
  "ini": "た",
  "name": "タッチ ミステリーオブトライアングル",
  "maker": "東宝",
  "releaseDate": "1987/3/14",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "198",
  "ini": "は",
  "name": "花のスター街道",
  "maker": "ビクター音楽産業",
  "releaseDate": "1987/3/17",
  "price": "5300",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "199",
  "ini": "く",
  "name": "グーニーズ2 フラッテリー最後の挑戦",
  "maker": "コナミ",
  "releaseDate": "1987/3/18",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "200",
  "ini": "ろ",
  "name": "ロストワード・オブ・ジェニー 失われたメッセージ",
  "maker": "タカラ",
  "releaseDate": "1987/3/25",
  "price": "5500",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "201",
  "ini": "な",
  "name": "南国指令!! スパイvsスパイ",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1987/3/27",
  "price": "4900",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "202",
  "ini": "さ",
  "name": "さんまの名探偵",
  "maker": "ナムコ",
  "releaseDate": "1987/4/2",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "203",
  "ini": "も",
  "name": "森田将棋",
  "maker": "セタ",
  "releaseDate": "1987/4/14",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "34"
 },
 {
  "id": "204",
  "ini": "ま",
  "name": "魔界島 七つの島の大冒険",
  "maker": "カプコン",
  "releaseDate": "1987/4/14",
  "price": "4980",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "205",
  "ini": "ね",
  "name": "熱血硬派くにおくん",
  "maker": "テクノスジャパン",
  "releaseDate": "1987/4/17",
  "price": "5300",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "206",
  "ini": "あ",
  "name": "アルゴスの戦士 はちゃめちゃ大進撃",
  "maker": "テクモ",
  "releaseDate": "1987/4/17",
  "price": "4980",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "207",
  "ini": "ほ",
  "name": "北斗の拳2 世紀末救世主伝説",
  "maker": "東映動画",
  "releaseDate": "1987/4/17",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "208",
  "ini": "ふ",
  "name": "ファミリージョッキー",
  "maker": "ナムコ",
  "releaseDate": "1987/4/24",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "209",
  "ini": "よ",
  "name": "妖怪倶楽部",
  "maker": "ジャレコ",
  "releaseDate": "1987/5/19",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "210",
  "ini": "た",
  "name": "ダイナマイトボウル",
  "maker": "東芝EMI",
  "releaseDate": "1987/5/24",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "30"
 },
 {
  "id": "211",
  "ini": "し",
  "name": "ジョギングレース（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1987/5/28",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "96"
 },
 {
  "id": "212",
  "ini": "あ",
  "name": "アテナ",
  "maker": "SNK",
  "releaseDate": "1987/6/5",
  "price": "5500",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "213",
  "ini": "た",
  "name": "高橋名人のBUGってハニー",
  "maker": "ハドソン",
  "releaseDate": "1987/6/5",
  "price": "5500",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "214",
  "ini": "と",
  "name": "闘人魔境伝 ヘラクレスの栄光　",
  "maker": "データイースト",
  "releaseDate": "1987/6/12",
  "price": "5300",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "215",
  "ini": "ふ",
  "name": "ファミリーボクシング",
  "maker": "ナムコ",
  "releaseDate": "1987/6/19",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "216",
  "ini": "う",
  "name": "うっでいぽこ",
  "maker": "デービーソフト",
  "releaseDate": "1987/6/20",
  "price": "5500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "217",
  "ini": "も",
  "name": "燃えろ！！プロ野球",
  "maker": "ジャレコ",
  "releaseDate": "1987/6/26",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "218",
  "ini": "と",
  "name": "所さんのまもるもせめるも",
  "maker": "EPIC・ソニー",
  "releaseDate": "1987/6/27",
  "price": "4900",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "219",
  "ini": "ほ",
  "name": "北海道連鎖殺人 オホーツクに消ゆ",
  "maker": "アスキー",
  "releaseDate": "1987/6/27",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "220",
  "ini": "み",
  "name": "未来神話ジャーヴァス",
  "maker": "タイトー",
  "releaseDate": "1987/6/30",
  "price": "5500",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "221",
  "ini": "そ",
  "name": "ゾンビハンター",
  "maker": "ハイスコアメディアワーク",
  "releaseDate": "1987/7/3",
  "price": "4900",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "222",
  "ini": "け",
  "name": "月風魔伝",
  "maker": "コナミ",
  "releaseDate": "1987/7/7",
  "price": "4900",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "223",
  "ini": "ふ",
  "name": "ブービーキッズ",
  "maker": "日本物産",
  "releaseDate": "1987/7/10",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "224",
  "ini": "へ",
  "name": "ヘクター'87",
  "maker": "ハドソン",
  "releaseDate": "1987/7/16",
  "price": "5000",
  "genre": "シューティング",
  "icNo": "53"
 },
 {
  "id": "225",
  "ini": "と",
  "name": "ドラゴンスレイヤーⅣ ドラスレファミリー",
  "maker": "ナムコ",
  "releaseDate": "1987/7/17",
  "price": "4900",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "226",
  "ini": "し",
  "name": "ジャンボウ",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1987/7/18",
  "price": "4980",
  "genre": "ブロック崩し",
  "icNo": "51"
 },
 {
  "id": "227",
  "ini": "ふ",
  "name": "ファンタジーゾーン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/7/20",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "228",
  "ini": "は",
  "name": "バツ&テリー 魔境の鉄人レース",
  "maker": "ユース",
  "releaseDate": "1987/7/22",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "229",
  "ini": "か",
  "name": "カラオケスタジオ",
  "maker": "バンダイ",
  "releaseDate": "1987/7/30",
  "price": "7500",
  "genre": "カラオケスタジオ",
  "icNo": "10"
 },
 {
  "id": "230",
  "ini": "ち",
  "name": "チェスター・フィールド",
  "maker": "ビック東海",
  "releaseDate": "1987/7/30",
  "price": "5300",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "231",
  "ini": "め",
  "name": "迷路大作戦（ファミリートレーナー専用）",
  "maker": "バンダイ",
  "releaseDate": "1987/7/31",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "97"
 },
 {
  "id": "232",
  "ini": "は",
  "name": "ハイウェイスター",
  "maker": "スクウェア",
  "releaseDate": "1987/8/7",
  "price": "4500",
  "genre": "3Dレース",
  "icNo": "1"
 },
 {
  "id": "233",
  "ini": "ほ",
  "name": "ボンバーキング",
  "maker": "ハドソン",
  "releaseDate": "1987/8/7",
  "price": "5500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "234",
  "ini": "み",
  "name": "ミラクルロピット 2100年の冒険",
  "maker": "キングレコード",
  "releaseDate": "1987/8/7",
  "price": "4900",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "235",
  "ini": "え",
  "name": "エルナークの財宝",
  "maker": "トーワチキ",
  "releaseDate": "1987/8/10",
  "price": "5300",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "236",
  "ini": "せ",
  "name": "聖闘士星矢 黄金伝説",
  "maker": "バンダイ",
  "releaseDate": "1987/8/10",
  "price": "5500",
  "genre": "アクションRPG",
  "icNo": "55"
 },
 {
  "id": "237",
  "ini": "ふ",
  "name": "ファミリーマージャン",
  "maker": "ナムコ",
  "releaseDate": "1987/8/11",
  "price": "3900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "238",
  "ini": "い",
  "name": "囲碁 九路盤対局",
  "maker": "BPS",
  "releaseDate": "1987/8/11",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "34"
 },
 {
  "id": "239",
  "ini": "て",
  "name": "天下のご意見番 水戸黄門",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/8/11",
  "price": "5300",
  "genre": "アクションアドベンチャー",
  "icNo": "0"
 },
 {
  "id": "240",
  "ini": "ま",
  "name": "魔城伝説Ⅱ 大魔司教ガリウス",
  "maker": "コナミ",
  "releaseDate": "1987/8/11",
  "price": "4900",
  "genre": "アクションRPG",
  "icNo": "1"
 },
 {
  "id": "241",
  "ini": "え",
  "name": "エアー・フォートレス",
  "maker": "HAL研究所",
  "releaseDate": "1987/8/17",
  "price": "5300",
  "genre": "アクション・シューティング",
  "icNo": "51"
 },
 {
  "id": "242",
  "ini": "む",
  "name": "夢幻戦士ヴァリス",
  "maker": "徳間書店",
  "releaseDate": "1987/8/21",
  "price": "5500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "243",
  "ini": "ひ",
  "name": "光の戦士フォトン",
  "maker": "タカラ",
  "releaseDate": "1987/8/28",
  "price": "5500",
  "genre": "3D・アクション",
  "icNo": "51"
 },
 {
  "id": "244",
  "ini": "ま",
  "name": "マンハッタンポリス（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1987/8/31",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "98"
 },
 {
  "id": "245",
  "ini": "あ",
  "name": "アラビアンドリーム シェラザード",
  "maker": "カルチャーブレーン",
  "releaseDate": "1987/9/3",
  "price": "5500",
  "genre": "アクション・RPG",
  "icNo": "52"
 },
 {
  "id": "246",
  "ini": "そ",
  "name": "ゾイド 中央大陸の戦い",
  "maker": "東芝EMI",
  "releaseDate": "1987/9/5",
  "price": "5300",
  "genre": "RPG・3D・シューティング",
  "icNo": "10"
 },
 {
  "id": "247",
  "ini": "す",
  "name": "SWAT",
  "maker": "東映動画",
  "releaseDate": "1987/9/11",
  "price": "5300",
  "genre": "アドベンチャー・RPG",
  "icNo": "1"
 },
 {
  "id": "248",
  "ini": "て",
  "name": "デジタルデビル物語 女神転生",
  "maker": "ナムコ",
  "releaseDate": "1987/9/11",
  "price": "4900",
  "genre": "3D・RPG",
  "icNo": "1"
 },
 {
  "id": "249",
  "ini": "す",
  "name": "スペランカーⅡ 勇者への挑戦",
  "maker": "ナムコ",
  "releaseDate": "1987/9/18",
  "price": "5300",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "250",
  "ini": "つ",
  "name": "つっぱり大相撲",
  "maker": "テクモ",
  "releaseDate": "1987/9/18",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "251",
  "ini": "は",
  "name": "バイオ戦士DAN インクリーザーとの闘い",
  "maker": "ジャレコ",
  "releaseDate": "1987/9/22",
  "price": "4900",
  "genre": "アクション・アドベンチャー",
  "icNo": "63"
 },
 {
  "id": "252",
  "ini": "す",
  "name": "スター・ゲート",
  "maker": "HAL研究所",
  "releaseDate": "1987/9/24",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "51"
 },
 {
  "id": "253",
  "ini": "い",
  "name": "井出洋介名人の実践麻雀",
  "maker": "カプコン",
  "releaseDate": "1987/9/24",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "254",
  "ini": "き",
  "name": "キョンシーズ2",
  "maker": "タイトー",
  "releaseDate": "1987/9/25",
  "price": "5500",
  "genre": "アクション・RPG",
  "icNo": "1"
 },
 {
  "id": "255",
  "ini": "さ",
  "name": "沙羅曼蛇",
  "maker": "コナミ",
  "releaseDate": "1987/9/25",
  "price": "4900",
  "genre": "シューティング",
  "icNo": "80"
 },
 {
  "id": "256",
  "ini": "み",
  "name": "ミリピート 巨大昆虫の逆襲",
  "maker": "HAL研究所",
  "releaseDate": "1987/10/1",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "51"
 },
 {
  "id": "257",
  "ini": "か",
  "name": "快傑ヤンチャ丸",
  "maker": "アイレム",
  "releaseDate": "1987/10/2",
  "price": "5500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "258",
  "ini": "う",
  "name": "ウルティマ 恐怖のエクソダス",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/10/9",
  "price": "5900",
  "genre": "RPG",
  "icNo": "51"
 },
 {
  "id": "259",
  "ini": "え",
  "name": "エスパ冒険隊 魔王の砦",
  "maker": "ジャレコ",
  "releaseDate": "1987/10/13",
  "price": "5300",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "260",
  "ini": "い",
  "name": "インドラの光",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1987/10/20",
  "price": "5300",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "261",
  "ini": "み",
  "name": "ミネルバトンサーガ ラゴンの復活",
  "maker": "タイトー",
  "releaseDate": "1987/10/23",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "262",
  "ini": "は",
  "name": "覇邪の封印",
  "maker": "アスキー",
  "releaseDate": "1987/10/23",
  "price": "5800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "263",
  "ini": "も",
  "name": "桃太郎伝説",
  "maker": "ハドソン",
  "releaseDate": "1987/10/26",
  "price": "5800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "264",
  "ini": "ほ",
  "name": "星をみるひと",
  "maker": "ホット・ビィ",
  "releaseDate": "1987/10/27",
  "price": "5300",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "265",
  "ini": "か",
  "name": "カラオケスタジオ トップヒット20 Vol.1",
  "maker": "バンダイ",
  "releaseDate": "1987/10/28",
  "price": "3980",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "266",
  "ini": "さ",
  "name": "サイドポケット",
  "maker": "ナムコ",
  "releaseDate": "1987/10/30",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "267",
  "ini": "し",
  "name": "ジャウスト",
  "maker": "HAL研究所",
  "releaseDate": "1987/10/30",
  "price": "3900",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "268",
  "ini": "ろ",
  "name": "ロマンシア",
  "maker": "東京書籍",
  "releaseDate": "1987/10/30",
  "price": "5300",
  "genre": "RPG",
  "icNo": "22"
 },
 {
  "id": "269",
  "ini": "る",
  "name": "ルパン三世 パンドラの遺産",
  "maker": "ナムコ",
  "releaseDate": "1987/11/6",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "270",
  "ini": "う",
  "name": "宇宙船コスモキャリア",
  "maker": "ジャレコ",
  "releaseDate": "1987/11/6",
  "price": "4900",
  "genre": "シミュレーション",
  "icNo": "51"
 },
 {
  "id": "271",
  "ini": "あ",
  "name": "アルテリオス",
  "maker": "日本物産",
  "releaseDate": "1987/11/13",
  "price": "5500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "272",
  "ini": "ふ",
  "name": "ファザナドゥ",
  "maker": "ハドソン",
  "releaseDate": "1987/11/16",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "273",
  "ini": "ま",
  "name": "マイクタイソン・パンチアウト!!",
  "maker": "任天堂",
  "releaseDate": "1987/11/21",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "274",
  "ini": "ふ",
  "name": "ファミトレ大運動会（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1987/11/27",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "99"
 },
 {
  "id": "275",
  "ini": "し",
  "name": "時空勇伝デビアス",
  "maker": "ナムコ",
  "releaseDate": "1987/11/27",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "276",
  "ini": "あ",
  "name": "アウトランダーズ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1987/12/4",
  "price": "5300",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "277",
  "ini": "す",
  "name": "スター・ウォーズ",
  "maker": "ナムコ",
  "releaseDate": "1987/12/4",
  "price": "4900",
  "genre": "アクション",
  "icNo": "81"
 },
 {
  "id": "278",
  "ini": "し",
  "name": "上海",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/12/4",
  "price": "5300",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "279",
  "ini": "と",
  "name": "ドラゴンスクロール 蘇りし魔竜",
  "maker": "コナミ",
  "releaseDate": "1987/12/4",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "280",
  "ini": "ら",
  "name": "ランボー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1987/12/4",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "281",
  "ini": "し",
  "name": "ＪＪ（ジェイジェイ）",
  "maker": "スクウェア",
  "releaseDate": "1987/12/7",
  "price": "4500",
  "genre": "シューティング",
  "icNo": "4"
 },
 {
  "id": "282",
  "ini": "こ",
  "name": "ゴルフ倶楽部 バーディラッシュ",
  "maker": "データイースト",
  "releaseDate": "1987/12/9",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "283",
  "ini": "と",
  "name": "トップガン",
  "maker": "コナミ",
  "releaseDate": "1987/12/11",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "284",
  "ini": "ふ",
  "name": "ファミリーテニス",
  "maker": "ナムコ",
  "releaseDate": "1987/12/11",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "285",
  "ini": "や",
  "name": "山村美紗サスペンス京都龍の寺殺人事件",
  "maker": "タイトー",
  "releaseDate": "1987/12/11",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "286",
  "ini": "て",
  "name": "鉄道王",
  "maker": "デービーソフト",
  "releaseDate": "1987/12/12",
  "price": "4900",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "287",
  "ini": "き",
  "name": "銀河の三人",
  "maker": "任天堂",
  "releaseDate": "1987/12/15",
  "price": "5000",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "288",
  "ini": "え",
  "name": "エキサイティングボクシング",
  "maker": "コナミ",
  "releaseDate": "1987/12/16",
  "price": "7980",
  "genre": "体感・スポーツ",
  "icNo": "1"
 },
 {
  "id": "289",
  "ini": "ろ",
  "name": "ロックマン",
  "maker": "カプコン",
  "releaseDate": "1987/12/17",
  "price": "5300",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "290",
  "ini": "か",
  "name": "カルノフ",
  "maker": "ナムコ",
  "releaseDate": "1987/12/18",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "291",
  "ini": "す",
  "name": "スティックハンター",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1987/12/18",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "292",
  "ini": "た",
  "name": "タイトーグランプリ 栄光へのライセンス",
  "maker": "タイトー",
  "releaseDate": "1987/12/18",
  "price": "5500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "293",
  "ini": "ふ",
  "name": "ファイナルファンタジー",
  "maker": "スクウェア",
  "releaseDate": "1987/12/18",
  "price": "5900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "294",
  "ini": "め",
  "name": "目指せパチプロ パチ夫くん",
  "maker": "ココナッツジャパン",
  "releaseDate": "1987/12/18",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "295",
  "ini": "う",
  "name": "ウィザードリィ",
  "maker": "アスキー",
  "releaseDate": "1987/12/22",
  "price": "5800",
  "genre": "3D・RPG",
  "icNo": "1"
 },
 {
  "id": "296",
  "ini": "け",
  "name": "ゲゲゲの鬼太郎2 妖怪軍団の挑戦",
  "maker": "バンダイ",
  "releaseDate": "1987/12/22",
  "price": "5500",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "297",
  "ini": "ふ",
  "name": "プロ野球ファミリースタジアム'87",
  "maker": "ナムコ",
  "releaseDate": "1987/12/22",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "298",
  "ini": "め",
  "name": "メタルギア",
  "maker": "コナミ",
  "releaseDate": "1987/12/22",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "299",
  "ini": "ま",
  "name": "マグナム危機一髪 エンパイアシティ1931",
  "maker": "東芝EMI",
  "releaseDate": "1987/12/25",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "300",
  "ini": "あ",
  "name": "アタックアニマル学園",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/12/26",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "54"
 },
 {
  "id": "301",
  "ini": "す",
  "name": "スーパーマン",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1987/12/26",
  "price": "5500",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "302",
  "ini": "ふ",
  "name": "突撃！風雲たけし城（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1987/12/28",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "90"
 },
 {
  "id": "303",
  "ini": "ふ",
  "name": "ファミリーサーキット",
  "maker": "ナムコ",
  "releaseDate": "1988/1/6",
  "price": "3900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "304",
  "ini": "さ",
  "name": "殺意の階層 ソフトハウス連続殺人事件",
  "maker": "HAL研究所",
  "releaseDate": "1988/1/7",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "305",
  "ini": "こ",
  "name": "コナミワイワイワールド",
  "maker": "コナミ",
  "releaseDate": "1988/1/14",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "306",
  "ini": "す",
  "name": "スケバン刑事Ⅲ",
  "maker": "東映動画",
  "releaseDate": "1988/1/22",
  "price": "5300",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "307",
  "ini": "り",
  "name": "リップルアイランド",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/1/23",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "308",
  "ini": "と",
  "name": "ドナルドランド",
  "maker": "データイースト",
  "releaseDate": "1988/1/29",
  "price": "5500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "309",
  "ini": "し",
  "name": "ジャンボ尾崎のホールインワン プロフェッシュナル",
  "maker": "HAL研究所",
  "releaseDate": "1988/2/1",
  "price": "5600",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "310",
  "ini": "は",
  "name": "パリ・ダカール・ラリー スペシャル",
  "maker": "CBS・ソニー",
  "releaseDate": "1988/2/1",
  "price": "5300",
  "genre": "レース",
  "icNo": "61"
 },
 {
  "id": "311",
  "ini": "か",
  "name": "仮面ライダー倶楽部 激突ショッカーランド",
  "maker": "バンダイ",
  "releaseDate": "1988/2/3",
  "price": "5500",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "312",
  "ini": "か",
  "name": "ガーディック外伝",
  "maker": "アイレム",
  "releaseDate": "1988/2/5",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "313",
  "ini": "こ",
  "name": "魂斗羅",
  "maker": "コナミ",
  "releaseDate": "1988/2/9",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "314",
  "ini": "と",
  "name": "ドラゴンクエストⅢ そして伝説へ…",
  "maker": "エニックス",
  "releaseDate": "1988/2/10",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "315",
  "ini": "か",
  "name": "カラオケスタジオ専用カセット トップヒット20 Vol.2",
  "maker": "バンダイ",
  "releaseDate": "1988/2/18",
  "price": "3980",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "316",
  "ini": "ま",
  "name": "松本亨の株式必勝学",
  "maker": "イマジニア",
  "releaseDate": "1988/2/18",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "317",
  "ini": "た",
  "name": "探偵 神宮寺三郎 横浜港連続殺人事件",
  "maker": "データイースト",
  "releaseDate": "1988/2/26",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "10"
 },
 {
  "id": "318",
  "ini": "て",
  "name": "鉄腕アトム",
  "maker": "コナミ",
  "releaseDate": "1988/2/26",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "319",
  "ini": "る",
  "name": "ルーカスフィルムゲーム ボールブレイザー",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/3/4",
  "price": "5500",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "320",
  "ini": "あ",
  "name": "アルカノイドⅡ",
  "maker": "タイトー",
  "releaseDate": "1988/3/8",
  "price": "5900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "321",
  "ini": "し",
  "name": "シュワルツネッガー プレデター",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1988/3/10",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "322",
  "ini": "つ",
  "name": " 釣りキチ三平　ブルーマーリン編",
  "maker": "ビクター音楽産業",
  "releaseDate": "1988/3/17",
  "price": "5500",
  "genre": "その他",
  "icNo": "52"
 },
 {
  "id": "323",
  "ini": "ろ",
  "name": "ローリングサンダー",
  "maker": "ナムコ",
  "releaseDate": "1989/3/17",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "324",
  "ini": "な",
  "name": "ナポレオン戦記",
  "maker": "アイレム",
  "releaseDate": "1988/3/18",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "325",
  "ini": "の",
  "name": "信長の野望　全国版",
  "maker": "光栄",
  "releaseDate": "1988/3/18",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "326",
  "ini": "た",
  "name": "谷川浩司の将棋指南Ⅱ 名人への道",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/3/18",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "327",
  "ini": "ふ",
  "name": "ファイティングゴルフ",
  "maker": "SNK",
  "releaseDate": "1988/3/24",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "328",
  "ini": "こ",
  "name": "ゴルゴ13 第一章 神々の黄昏",
  "maker": "ビック東海",
  "releaseDate": "1988/3/26",
  "price": "5700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "329",
  "ini": "お",
  "name": "オリュンポスの戦い 愛の伝説",
  "maker": "イマジニア",
  "releaseDate": "1988/3/28",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "330",
  "ini": "た",
  "name": "武田信玄",
  "maker": "ホット・ビィ",
  "releaseDate": "1988/3/28",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "331",
  "ini": "ふ",
  "name": "フリートコマンダー",
  "maker": "アスキー",
  "releaseDate": "1988/3/29",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "332",
  "ini": "ふ",
  "name": "不動明王伝",
  "maker": "タイトー",
  "releaseDate": "1988/3/29",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "333",
  "ini": "ひ",
  "name": "ビーバップハイスクール 高校生極楽伝説",
  "maker": "データイースト",
  "releaseDate": "1988/3/30",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "21"
 },
 {
  "id": "334",
  "ini": "と",
  "name": "独眼竜政宗",
  "maker": "ナムコ",
  "releaseDate": "1988/4/5",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "335",
  "ini": "し",
  "name": "ジーキル博士の彷魔が刻",
  "maker": "東宝",
  "releaseDate": "1988/4/8",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "336",
  "ini": "た",
  "name": "双截龍",
  "maker": "テクノスジャパン",
  "releaseDate": "1988/4/8",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "337",
  "ini": "も",
  "name": "燃えろ!! プロテニス",
  "maker": "ジャレコ",
  "releaseDate": "1988/4/15",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "90"
 },
 {
  "id": "338",
  "ini": "い",
  "name": "怒Ⅱ DOGOSOKEN",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1988/4/16",
  "price": "5500",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "339",
  "ini": "え",
  "name": "エリュシオン",
  "maker": "東京書籍",
  "releaseDate": "1988/4/28",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "340",
  "ini": "き",
  "name": "キャプテン翼",
  "maker": "テクモ",
  "releaseDate": "1988/4/28",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "21"
 },
 {
  "id": "341",
  "ini": "せ",
  "name": "戦車戦略 砂漠の狐",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1988/4/28",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "50"
 },
 {
  "id": "342",
  "ini": "め",
  "name": "名探偵ホームズ 霧のロンドン殺人事件",
  "maker": "トーワチキ",
  "releaseDate": "1988/5/13",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "343",
  "ini": "て",
  "name": "ディープダンジョンⅢ 勇士への旅",
  "maker": "スクウェア",
  "releaseDate": "1988/5/13",
  "price": "5900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "344",
  "ini": "か",
  "name": " 仮面の忍者 赤影",
  "maker": "東映動画",
  "releaseDate": "1988/5/20",
  "price": "5300",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "345",
  "ini": "し",
  "name": "将軍 ＳＨＯＧＵＮ",
  "maker": "ヘクト",
  "releaseDate": "1988/5/27",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "346",
  "ini": "さ",
  "name": "サラダの国のトマト姫",
  "maker": "ハドソン",
  "releaseDate": "1988/5/27",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "64"
 },
 {
  "id": "347",
  "ini": "な",
  "name": "ナムコクラシック",
  "maker": "ナムコ",
  "releaseDate": "1988/5/27",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "348",
  "ini": "に",
  "name": "忍者くん 阿修羅の章",
  "maker": "UPL",
  "releaseDate": "1988/5/27",
  "price": "5300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "349",
  "ini": "せ",
  "name": "聖闘士星矢 黄金伝説完結編",
  "maker": "バンダイ",
  "releaseDate": "1988/5/30",
  "price": "5800",
  "genre": "RPG",
  "icNo": "55"
 },
 {
  "id": "350",
  "ini": "こ",
  "name": "コスモポリス ギャリバン",
  "maker": "日本物産",
  "releaseDate": "1988/6/3",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "351",
  "ini": "ち",
  "name": "超惑星戦記 メタファイト",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/6/17",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "352",
  "ini": "い",
  "name": "1943",
  "maker": "カプコン",
  "releaseDate": "1988/6/20",
  "price": "5300",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "353",
  "ini": "よ",
  "name": "妖怪道中記",
  "maker": "ナムコ",
  "releaseDate": "1988/6/24",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "354",
  "ini": "さ",
  "name": "真田十勇士",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1988/6/27",
  "price": "5800",
  "genre": "RPG",
  "icNo": "51"
 },
 {
  "id": "355",
  "ini": "き",
  "name": "究極ハリキリスタジアム",
  "maker": "タイトー",
  "releaseDate": "1988/6/28",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "356",
  "ini": "す",
  "name": "スーパーブラックオニキス",
  "maker": "BPS",
  "releaseDate": "1988/7/14",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "357",
  "ini": "し",
  "name": "じゃりん子チエ ばくだん娘のし幸せさがし",
  "maker": "コナミ",
  "releaseDate": "1988/7/15",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "358",
  "ini": "へ",
  "name": "ベストプレープロ野球",
  "maker": "アスキー",
  "releaseDate": "1988/7/15",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "359",
  "ini": "て",
  "name": "伝説の騎士エルロンド",
  "maker": "ジャレコ",
  "releaseDate": "1988/7/15",
  "price": "5500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "360",
  "ini": "ひ",
  "name": "ヒットラーの復活　TOP SECRET",
  "maker": "カプコン",
  "releaseDate": "1988/7/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "361",
  "ini": "め",
  "name": "めぞん一刻",
  "maker": "ボーステック",
  "releaseDate": "1988/7/21",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "362",
  "ini": "か",
  "name": "カイの冒険",
  "maker": "ナムコ",
  "releaseDate": "1988/7/22",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "363",
  "ini": "か",
  "name": "カケフ君のジャンプ天国 スピード地獄",
  "maker": "ビック東海",
  "releaseDate": "1988/7/22",
  "price": "5300",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "364",
  "ini": "れ",
  "name": "レインボーアイランド",
  "maker": "タイトー",
  "releaseDate": "1988/7/26",
  "price": "5500",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "365",
  "ini": "ね",
  "name": "熱血高校ドッジボール部",
  "maker": "テクノスジャパン",
  "releaseDate": "1988/7/26",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "366",
  "ini": "く",
  "name": "グレートタンク",
  "maker": "SNK",
  "releaseDate": "1988/7/29",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "367",
  "ini": "さ",
  "name": "三国志 中原の覇者",
  "maker": "ナムコ",
  "releaseDate": "1988/7/29",
  "price": "6900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "368",
  "ini": "ひ",
  "name": "飛龍の拳Ⅱ ドラゴンの翼",
  "maker": "カルチャーブレーン",
  "releaseDate": "1988/7/29",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "369",
  "ini": "す",
  "name": "スーパーリアルベースボール",
  "maker": "バップ",
  "releaseDate": "1988/7/30",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "5"
 },
 {
  "id": "370",
  "ini": "た",
  "name": "太陽の神殿",
  "maker": "東京書籍",
  "releaseDate": "1988/8/3",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "371",
  "ini": "え",
  "name": "エッガーランド 迷宮の復活",
  "maker": "HAL研究所",
  "releaseDate": "1988/8/9",
  "price": "5600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "372",
  "ini": "さ",
  "name": "ザ・マネーゲーム",
  "maker": "ソフエル",
  "releaseDate": "1988/8/10",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "373",
  "ini": "た",
  "name": "闘将!!拉麺男 炸裂超人一○二芸",
  "maker": "バンダイ",
  "releaseDate": "1988/8/10",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "55"
 },
 {
  "id": "374",
  "ini": "も",
  "name": "燃えろ!! プロ野球'88決定版",
  "maker": "ジャレコ",
  "releaseDate": "1988/8/10",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "90"
 },
 {
  "id": "375",
  "ini": "み",
  "name": "水戸黄門Ⅱ 世界漫遊記",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/8/11",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "376",
  "ini": "う",
  "name": "ウィナーズカップ",
  "maker": "データイースト",
  "releaseDate": "1988/8/12",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "21"
 },
 {
  "id": "377",
  "ini": "と",
  "name": "ドラゴンボール 大魔王復活",
  "maker": "バンダイ",
  "releaseDate": "1988/8/12",
  "price": "5800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "378",
  "ini": "ふ",
  "name": "ファイナルラップ",
  "maker": "ナムコ",
  "releaseDate": "1988/8/12",
  "price": "5200",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "379",
  "ini": "ふ",
  "name": "ファミコンウォーズ",
  "maker": "任天堂",
  "releaseDate": "1988/8/12",
  "price": "5500",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "380",
  "ini": "ま",
  "name": "マッド・シティ",
  "maker": "コナミ",
  "releaseDate": "1988/8/12",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "381",
  "ini": "ほ",
  "name": "不如帰",
  "maker": "アイレム",
  "releaseDate": "1988/8/19",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "382",
  "ini": "す",
  "name": "スーパーピンボール",
  "maker": "ココナッツジャパン",
  "releaseDate": "1988/8/23",
  "price": "5800",
  "genre": "その他",
  "icNo": "30"
 },
 {
  "id": "383",
  "ini": "い",
  "name": "イース",
  "maker": "ビクター音楽産業",
  "releaseDate": "1988/8/26",
  "price": "6200",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "384",
  "ini": "す",
  "name": "スーパーダイナミックス バドミントン",
  "maker": "バップ",
  "releaseDate": "1988/8/26",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "11"
 },
 {
  "id": "385",
  "ini": "か",
  "name": "亀の恩返し ウラシマ伝説",
  "maker": "ハドソン",
  "releaseDate": "1988/8/26",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "386",
  "ini": "ふ",
  "name": "ファミコン名人戦",
  "maker": "SNK",
  "releaseDate": "1988/9/2",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "387",
  "ini": "ま",
  "name": "マニアックマンション",
  "maker": "ジャレコ",
  "releaseDate": "1988/9/13",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "90"
 },
 {
  "id": "388",
  "ini": "こ",
  "name": "コナミックスポーツ イン ソウル",
  "maker": "コナミ",
  "releaseDate": "1988/9/16",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "389",
  "ini": "れ",
  "name": "霊幻道士",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/9/16",
  "price": "5900",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "390",
  "ini": "く",
  "name": "孔雀王",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/9/21",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "10"
 },
 {
  "id": "391",
  "ini": "と",
  "name": "ドナルドダック",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1988/9/22",
  "price": "5800",
  "genre": "その他",
  "icNo": "51"
 },
 {
  "id": "392",
  "ini": "え",
  "name": "エイト・アイズ",
  "maker": "セタ",
  "releaseDate": "1988/9/27",
  "price": "5800",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "393",
  "ini": "え",
  "name": "えりかとさとるの夢冒険",
  "maker": "ナムコ",
  "releaseDate": "1988/9/27",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "394",
  "ini": "ひ",
  "name": "VIVA LASVEGAS",
  "maker": "EPIC・ソニー",
  "releaseDate": "1988/9/30",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "395",
  "ini": "な",
  "name": "ナイトライダー",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1988/9/30",
  "price": "5500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "396",
  "ini": "へ",
  "name": "ベストプレープロ野球 新データ",
  "maker": "アスキー",
  "releaseDate": "1988/10/11",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "397",
  "ini": "た",
  "name": "大戦略",
  "maker": "ボーステック",
  "releaseDate": "1988/10/11",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "398",
  "ini": "さ",
  "name": "ザ・ブラックバスⅡ",
  "maker": "ホット・ビィ",
  "releaseDate": "1988/10/18",
  "price": "5800",
  "genre": "その他",
  "icNo": "54"
 },
 {
  "id": "399",
  "ini": "こ",
  "name": "コブラコマンド",
  "maker": "データイースト",
  "releaseDate": "1988/10/21",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "21"
 },
 {
  "id": "400",
  "ini": "け",
  "name": "源平討魔伝",
  "maker": "ナムコ",
  "releaseDate": "1988/10/21",
  "price": "4900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "401",
  "ini": "す",
  "name": "スーパーマリオブラザーズ3",
  "maker": "任天堂",
  "releaseDate": "1988/10/23",
  "price": "6500",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "402",
  "ini": "あ",
  "name": "アメリカ大統領選挙",
  "maker": "ヘクト",
  "releaseDate": "1988/10/28",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "403",
  "ini": "さ",
  "name": "三國志",
  "maker": "光栄",
  "releaseDate": "1988/10/30",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "404",
  "ini": "と",
  "name": "東方見文録",
  "maker": "ナツメ",
  "releaseDate": "1988/11/10",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "405",
  "ini": "あ",
  "name": "アメリカンフットボール タッチダウンフィーバー",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1988/11/11",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "51"
 },
 {
  "id": "406",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派",
  "maker": "アスミック",
  "releaseDate": "1988/11/11",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "407",
  "ini": "さ",
  "name": "西遊記ワールド",
  "maker": "ジャレコ",
  "releaseDate": "1988/11/11",
  "price": "5500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "408",
  "ini": "ふ",
  "name": "ファミリークイズ 4人はライバル",
  "maker": "アテナ",
  "releaseDate": "1988/11/16",
  "price": "5800",
  "genre": "その他",
  "icNo": "20"
 },
 {
  "id": "409",
  "ini": "か",
  "name": "貝獣物語",
  "maker": "ナムコ",
  "releaseDate": "1988/11/18",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "410",
  "ini": "て",
  "name": "ディジャブ 悪魔は本当にやって来た",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1988/11/22",
  "price": "9800",
  "genre": "アドベンチャー",
  "icNo": "50"
 },
 {
  "id": "411",
  "ini": "も",
  "name": "燃えろ!! ジュニアバスケット",
  "maker": "ジャレコ",
  "releaseDate": "1988/11/22",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "90"
 },
 {
  "id": "412",
  "ini": "た",
  "name": "たけしの戦国風雲児",
  "maker": "タイトー",
  "releaseDate": "1988/11/25",
  "price": "5800",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "413",
  "ini": "ふ",
  "name": "ファミリーマージャンⅡ 上海への道",
  "maker": "ナムコ",
  "releaseDate": "1988/11/25",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "414",
  "ini": "ち",
  "name": "中国占星術",
  "maker": "ジャレコ",
  "releaseDate": "1988/11/29",
  "price": "5900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "415",
  "ini": "え",
  "name": "エイリアンシンドローム",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/12/2",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "416",
  "ini": "も",
  "name": "桃太郎電鉄",
  "maker": "ハドソン",
  "releaseDate": "1988/12/2",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "417",
  "ini": "と",
  "name": "突然！マッチョマン",
  "maker": "ビック東海",
  "releaseDate": "1988/12/2",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "418",
  "ini": "は",
  "name": "半熟英雄",
  "maker": "スクウェア",
  "releaseDate": "1988/12/2",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "54"
 },
 {
  "id": "419",
  "ini": "き",
  "name": "キングオブキングス",
  "maker": "ナムコ",
  "releaseDate": "1988/12/9",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "420",
  "ini": "こ",
  "name": "ゴジラ",
  "maker": "東宝",
  "releaseDate": "1988/12/9",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "421",
  "ini": "な",
  "name": "中嶋悟 F-1 HERO",
  "maker": "バリエ",
  "releaseDate": "1988/12/9",
  "price": "5900",
  "genre": "レース",
  "icNo": "30"
 },
 {
  "id": "422",
  "ini": "に",
  "name": "忍者龍剣伝",
  "maker": "テクモ",
  "releaseDate": "1988/12/9",
  "price": "5500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "423",
  "ini": "は",
  "name": "89電脳九星占い by Gingukan",
  "maker": "インダクション・プロディース",
  "releaseDate": "1988/12/10",
  "price": "9800",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "424",
  "ini": "ふ",
  "name": "ファイティングロード",
  "maker": "東映動画",
  "releaseDate": "1988/12/13",
  "price": "6200",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "425",
  "ini": "か",
  "name": "かぐや姫伝説",
  "maker": "ビクター音楽産業",
  "releaseDate": "1988/12/16",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "54"
 },
 {
  "id": "426",
  "ini": "き",
  "name": "キャプテン シルバー",
  "maker": "徳間書店",
  "releaseDate": "1988/12/16",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "427",
  "ini": "く",
  "name": "グラディウスⅡ",
  "maker": "コナミ",
  "releaseDate": "1988/12/16",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "428",
  "ini": "さ",
  "name": "ザ・トライアスロン",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1988/12/16",
  "price": "5600",
  "genre": "レース",
  "icNo": "52"
 },
 {
  "id": "429",
  "ini": "き",
  "name": "究極ハリキリスタジアム'88 選手新データバージョン",
  "maker": "タイトー",
  "releaseDate": "1988/12/16",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "430",
  "ini": "さ",
  "name": "サイクルレース ロードマン ～激走!! 日本一周4000km～",
  "maker": "東京書籍",
  "releaseDate": "1988/12/17",
  "price": "5500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "431",
  "ini": "と",
  "name": "トップライダー",
  "maker": "バリエ",
  "releaseDate": "1988/12/17",
  "price": "9800",
  "genre": "レース",
  "icNo": "44"
 },
 {
  "id": "432",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅡ",
  "maker": "スクウェア",
  "releaseDate": "1988/12/17",
  "price": "6500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "433",
  "ini": "ふ",
  "name": "風雲たけし城二（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1988/12/20",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "90"
 },
 {
  "id": "434",
  "ini": "ふ",
  "name": "ファンタジーゾーンⅡ オパオパの涙",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/12/20",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "435",
  "ini": "ふ",
  "name": "プロ野球 ファミリースタジアム'88",
  "maker": "ナムコ",
  "releaseDate": "1988/12/20",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "436",
  "ini": "ろ",
  "name": "ローラーボール",
  "maker": "HAL研究所",
  "releaseDate": "1988/12/20",
  "price": "5600",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "437",
  "ini": "き",
  "name": "銀河英雄伝説",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1988/12/21",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "51"
 },
 {
  "id": "438",
  "ini": "て",
  "name": "テトリス",
  "maker": "BPS",
  "releaseDate": "1988/12/22",
  "price": "4900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "439",
  "ini": "も",
  "name": "燃えろ!! プロサッカー",
  "maker": "ジャレコ",
  "releaseDate": "1988/12/23",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "90"
 },
 {
  "id": "440",
  "ini": "あ",
  "name": "アキラ",
  "maker": "タイトー",
  "releaseDate": "1988/12/24",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "441",
  "ini": "え",
  "name": "エアーウルフ",
  "maker": "九娯貿易",
  "releaseDate": "1988/12/24",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "442",
  "ini": "し",
  "name": "小公子セディ",
  "maker": "フジテレビ",
  "releaseDate": "1988/12/24",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "443",
  "ini": "ふ",
  "name": "プロ野球？殺人事件！",
  "maker": "カプコン",
  "releaseDate": "1988/12/24",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "64"
 },
 {
  "id": "444",
  "ini": "ろ",
  "name": "ロックマン2 Ｄｒ．ワイリーの謎",
  "maker": "カプコン",
  "releaseDate": "1988/12/24",
  "price": "5800",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "445",
  "ini": "け",
  "name": "ゲバラ",
  "maker": "SNK",
  "releaseDate": "1988/12/26",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "446",
  "ini": "か",
  "name": "がんばれゴエモン2",
  "maker": "コナミ",
  "releaseDate": "1989/1/4",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "447",
  "ini": "す",
  "name": "スペースハリアー",
  "maker": "タカラ",
  "releaseDate": "1989/1/6",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "448",
  "ini": "ひ",
  "name": "100万$キッド 幻の帝王編",
  "maker": "ソフエル",
  "releaseDate": "1989/1/6",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "449",
  "ini": "に",
  "name": "西村京太郎ミステリー ブルートレイン殺人事件",
  "maker": "アイレム",
  "releaseDate": "1989/1/20",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "450",
  "ini": "さ",
  "name": "里見八犬伝",
  "maker": "SNK",
  "releaseDate": "1989/1/20",
  "price": "5900",
  "genre": "RPG",
  "icNo": "31"
 },
 {
  "id": "451",
  "ini": "ふ",
  "name": "来来キョンシーズ ベビーキョンシーのあみだ大冒険（ファミリートレーナー）",
  "maker": "バンダイ",
  "releaseDate": "1989/1/26",
  "price": "4900",
  "genre": "ファミリートレーナー",
  "icNo": "90"
 },
 {
  "id": "452",
  "ini": "そ",
  "name": "ゾイド2 ゼネバスの逆襲",
  "maker": "東芝EMI",
  "releaseDate": "1989/1/27",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "453",
  "ini": "も",
  "name": "モトクロスチャンピオン",
  "maker": "コナミ",
  "releaseDate": "1989/1/27",
  "price": "5500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "454",
  "ini": "て",
  "name": "電撃ビッグバン！",
  "maker": "ビック東海",
  "releaseDate": "1989/1/27",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "455",
  "ini": "は",
  "name": "パチ夫くん2",
  "maker": "ココナッツジャパン",
  "releaseDate": "1989/1/30",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "22"
 },
 {
  "id": "456",
  "ini": "わ",
  "name": "POLE TO FINISH",
  "maker": "データイースト",
  "releaseDate": "1989/1/31",
  "price": "5800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "457",
  "ini": "と",
  "name": "トム・ソーヤの冒険",
  "maker": "セタ",
  "releaseDate": "1989/2/6",
  "price": "5900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "458",
  "ini": "わ",
  "name": "ワギャンランド",
  "maker": "ナムコ",
  "releaseDate": "1989/2/9",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "459",
  "ini": "せ",
  "name": "赤龍王",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/2/10",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "460",
  "ini": "や",
  "name": "山村美紗サスペンス 京都花の密室殺人事件　",
  "maker": "タイトー",
  "releaseDate": "1989/2/11",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "62"
 },
 {
  "id": "461",
  "ini": "ふ",
  "name": "ファミコンジャンプ 英雄列伝",
  "maker": "バンダイ",
  "releaseDate": "1989/2/25",
  "price": "6500",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "462",
  "ini": "は",
  "name": "ハイドライド3 闇からの訪問者",
  "maker": "ナムコ",
  "releaseDate": "1989/2/17",
  "price": "6900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "463",
  "ini": "ふ",
  "name": "飛ingヒーロー",
  "maker": "EPIC・ソニー",
  "releaseDate": "1989/2/17",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "464",
  "ini": "す",
  "name": "スペースシャドー",
  "maker": "バンダイ",
  "releaseDate": "1989/2/20",
  "price": "9800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "465",
  "ini": "う",
  "name": "ウィザードリィⅡ リルガミンの遺産",
  "maker": "アスキー",
  "releaseDate": "1989/2/21",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "466",
  "ini": "ひ",
  "name": "百鬼夜行",
  "maker": "ユース",
  "releaseDate": "1989/2/23",
  "price": "6200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "467",
  "ini": "か",
  "name": "がんばれペナントレース！",
  "maker": "コナミ",
  "releaseDate": "1989/2/28",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "468",
  "ini": "さ",
  "name": "魁!!男塾 疾風一号生",
  "maker": "バンダイ",
  "releaseDate": "1989/3/3",
  "price": "5800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "469",
  "ini": "は",
  "name": "早打ちスーパー囲碁",
  "maker": "ナムコ",
  "releaseDate": "1989/3/3",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "470",
  "ini": "し",
  "name": "ジーザス 恐怖のバイオ・モンスター",
  "maker": "キングレコード",
  "releaseDate": "1989/3/17",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "471",
  "ini": "は",
  "name": "爆笑!!人生劇場",
  "maker": "タイトー",
  "releaseDate": "1989/3/17",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "472",
  "ini": "ふ",
  "name": "ファミリーピンボール",
  "maker": "ナムコ",
  "releaseDate": "1989/3/24",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "473",
  "ini": "あ",
  "name": "暗黒神話 ヤマトタケル伝説",
  "maker": "東京書籍",
  "releaseDate": "1989/3/24",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "474",
  "ini": "し",
  "name": "じゃじゃ丸忍法帳",
  "maker": "ジャレコ",
  "releaseDate": "1989/3/28",
  "price": "5800",
  "genre": "RPG",
  "icNo": "90"
 },
 {
  "id": "475",
  "ini": "あ",
  "name": "アフターバーナー",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/3/30",
  "price": "6200",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "476",
  "ini": "へ",
  "name": "ペナントリーグ ホームランナイター",
  "maker": "データイースト",
  "releaseDate": "1989/3/31",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "63"
 },
 {
  "id": "477",
  "ini": "お",
  "name": "オペレーションウルフ",
  "maker": "タイトー",
  "releaseDate": "1989/3/31",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "478",
  "ini": "し",
  "name": "シャドウゲイト",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1989/3/31",
  "price": "6200",
  "genre": "アドベンチャー",
  "icNo": "50"
 },
 {
  "id": "479",
  "ini": "ま",
  "name": "松本亨の株式必勝学Ⅱ",
  "maker": "イマジニア",
  "releaseDate": "1989/3/31",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "480",
  "ini": "か",
  "name": "ガーフィールドの一週間",
  "maker": "トーワチキ",
  "releaseDate": "1989/4/7",
  "price": "5500",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "481",
  "ini": "と",
  "name": "ドラゴンスピリット 新たなる伝説",
  "maker": "ナムコ",
  "releaseDate": "1989/4/14",
  "price": "5200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "482",
  "ini": "ま",
  "name": "マインドシーカー",
  "maker": "ナムコ",
  "releaseDate": "1989/4/18",
  "price": "6500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "483",
  "ini": "あ",
  "name": "蒼き狼と白き牝鹿 ジンギスカン",
  "maker": "光栄",
  "releaseDate": "1989/4/20",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "484",
  "ini": "た",
  "name": "ダウンタウン熱血物語",
  "maker": "テクノスジャパン",
  "releaseDate": "1989/4/25",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "485",
  "ini": "て",
  "name": "デビルマン",
  "maker": "ナムコ",
  "releaseDate": "1989/4/25",
  "price": "5500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "486",
  "ini": "と",
  "name": "ドラゴンバスターⅡ 闇の封印",
  "maker": "ナムコ",
  "releaseDate": "1989/4/27",
  "price": "5200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "487",
  "ini": "ほ",
  "name": "HOLY DIVER",
  "maker": "アイレム",
  "releaseDate": "1989/4/28",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "488",
  "ini": "け",
  "name": "KEIBA SIMULATION 本命",
  "maker": "日本物産",
  "releaseDate": "1989/4/28",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "489",
  "ini": "め",
  "name": "名探偵ホームズ Mからの挑戦状",
  "maker": "トーワチキ",
  "releaseDate": "1989/5/1",
  "price": "6000",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "490",
  "ini": "か",
  "name": "株式道場 実践編",
  "maker": "ヘクト",
  "releaseDate": "1989/5/2",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "491",
  "ini": "け",
  "name": "激亀忍者伝",
  "maker": "コナミ",
  "releaseDate": "1989/5/12",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "492",
  "ini": "へ",
  "name": "ベースボールスター めざせ三冠王",
  "maker": "SNK",
  "releaseDate": "1989/5/19",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "22"
 },
 {
  "id": "493",
  "ini": "て",
  "name": "天地を喰らう",
  "maker": "カプコン",
  "releaseDate": "1989/5/19",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "494",
  "ini": "す",
  "name": "スーパーチャイニーズ2 ドラゴンキッド",
  "maker": "カルチャーブレーン",
  "releaseDate": "1989/5/26",
  "price": "5900",
  "genre": "RPG",
  "icNo": "51"
 },
 {
  "id": "495",
  "ini": "か",
  "name": "帰って来た！軍人将棋 なんやそれ？",
  "maker": "ソフエル",
  "releaseDate": "1989/5/26",
  "price": "5500",
  "genre": "テーブル",
  "icNo": "40"
 },
 {
  "id": "496",
  "ini": "ら",
  "name": "ラサール石井のチャイルズクエスト",
  "maker": "ナムコ",
  "releaseDate": "1989/6/23",
  "price": "5500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "497",
  "ini": "え",
  "name": "SDガンダムワールド ガチャポン戦士2 カプセル戦記",
  "maker": "バンダイ",
  "releaseDate": "1989/6/25",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "498",
  "ini": "く",
  "name": "クインティ",
  "maker": "ナムコ",
  "releaseDate": "1989/6/27",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "499",
  "ini": "ま",
  "name": "マーダークラブ",
  "maker": "セタ",
  "releaseDate": "1989/6/30",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "500",
  "ini": "た",
  "name": "脱獄",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1989/6/30",
  "price": "5600",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "501",
  "ini": "し",
  "name": "新・燃えろ!! プロ野球",
  "maker": "ジャレコ",
  "releaseDate": "1989/7/13",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "502",
  "ini": "と",
  "name": "ドラゴンニンジャ",
  "maker": "ナムコ",
  "releaseDate": "1989/7/14",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "503",
  "ini": "ほ",
  "name": "ホワイトライオン伝説 ピラミットの彼方に",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1989/7/14",
  "price": "5900",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "504",
  "ini": "い",
  "name": "囲碁指南",
  "maker": "ヘクト",
  "releaseDate": "1989/7/14",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "505",
  "ini": "う",
  "name": "WILLOW",
  "maker": "カプコン",
  "releaseDate": "1989/7/18",
  "price": "5900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "506",
  "ini": "ひ",
  "name": "必殺道場破り",
  "maker": "シグマ商事",
  "releaseDate": "1989/7/18",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "507",
  "ini": "け",
  "name": "ケルナグール",
  "maker": "ナムコ",
  "releaseDate": "1989/7/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "508",
  "ini": "ふ",
  "name": "ファリア 封印の剣",
  "maker": "ハイスコアメディアワーク",
  "releaseDate": "1989/7/21",
  "price": "6200",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "509",
  "ini": "き",
  "name": "究極ハリキリスタジアム 平成元年版",
  "maker": "タイトー",
  "releaseDate": "1989/7/21",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "510",
  "ini": "せ",
  "name": "全米プロバスケット",
  "maker": "ビック東海",
  "releaseDate": "1989/7/21",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "511",
  "ini": "お",
  "name": "美味しんぼ 究極のメニュー三本勝負",
  "maker": "バンダイ",
  "releaseDate": "1989/7/25",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "3"
 },
 {
  "id": "512",
  "ini": "は",
  "name": "パーフェクト ボウリング",
  "maker": "トンキンハウス（東京書籍）",
  "releaseDate": "1989/7/25",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "513",
  "ini": "ま",
  "name": "MOTHER",
  "maker": "任天堂",
  "releaseDate": "1989/7/27",
  "price": "6500",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "514",
  "ini": "ふ",
  "name": "ファミスタ'89 開幕版!!",
  "maker": "ナムコ",
  "releaseDate": "1989/7/28",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "515",
  "ini": "す",
  "name": "スプラッターハウス わんぱくグラフィティ",
  "maker": "ナムコ",
  "releaseDate": "1989/7/31",
  "price": "4900",
  "genre": "アクション",
  "icNo": "94"
 },
 {
  "id": "516",
  "ini": "こ",
  "name": "コズミックウォーズ",
  "maker": "コナミ",
  "releaseDate": "1989/8/4",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "517",
  "ini": "き",
  "name": "究極タイガー",
  "maker": "CBS・ソニー",
  "releaseDate": "1989/8/4",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "0"
 },
 {
  "id": "518",
  "ini": "も",
  "name": "燃える！お兄さん",
  "maker": "東宝",
  "releaseDate": "1989/8/8",
  "price": "5800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "519",
  "ini": "め",
  "name": "名門！第三野球部",
  "maker": "バンダイ",
  "releaseDate": "1989/8/8",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "4"
 },
 {
  "id": "520",
  "ini": "む",
  "name": "ムーランルージュ戦記 メルヴィルの炎",
  "maker": "学習研究社",
  "releaseDate": "1989/8/11",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "521",
  "ini": "た",
  "name": "武田信玄2",
  "maker": "ホット・ビィ",
  "releaseDate": "1989/8/21",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "522",
  "ini": "き",
  "name": "Captain ED",
  "maker": "CBS・ソニー",
  "releaseDate": "1989/8/25",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "523",
  "ini": "れ",
  "name": "レーサーミニ四駆 ジャパンカップ",
  "maker": "コナミ",
  "releaseDate": "1989/8/25",
  "price": "5900",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "524",
  "ini": "ろ",
  "name": "ロボコップ",
  "maker": "データイースト",
  "releaseDate": "1989/8/25",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "525",
  "ini": "け",
  "name": "激闘プロレス!! 闘魂伝説",
  "maker": "テクモ",
  "releaseDate": "1989/9/1",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "526",
  "ini": "あ",
  "name": "アイドル八犬伝",
  "maker": "トーワチキ",
  "releaseDate": "1989/9/14",
  "price": "6000",
  "genre": "アドベンチャー",
  "icNo": "53"
 },
 {
  "id": "527",
  "ini": "た",
  "name": "谷川浩司の将棋指南Ⅲ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/9/14",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "528",
  "ini": "ま",
  "name": "マルサの女",
  "maker": "カプコン",
  "releaseDate": "1989/9/19",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "529",
  "ini": "う",
  "name": "ウルティマ 聖者への道",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/9/20",
  "price": "5900",
  "genre": "RPG",
  "icNo": "34"
 },
 {
  "id": "530",
  "ini": "ち",
  "name": "中華大仙",
  "maker": "タイトー",
  "releaseDate": "1989/9/22",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "64"
 },
 {
  "id": "531",
  "ini": "あ",
  "name": "アメリカンドリーム",
  "maker": "ココナッツジャパン",
  "releaseDate": "1989/9/23",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "532",
  "ini": "さ",
  "name": "サンダーバード",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/9/29",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "533",
  "ini": "つ",
  "name": "ツインビー3 ポコポコ大魔王",
  "maker": "コナミ",
  "releaseDate": "1989/9/29",
  "price": "5500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "534",
  "ini": "ま",
  "name": "マハラジャ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/9/29",
  "price": "6200",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "535",
  "ini": "あ",
  "name": "悪魔の招待状",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1989/9/29",
  "price": "6200",
  "genre": "アドベンチャー",
  "icNo": "50"
 },
 {
  "id": "536",
  "ini": "め",
  "name": "明治維新",
  "maker": "ユース",
  "releaseDate": "1989/9/29",
  "price": "6200",
  "genre": "RPG",
  "icNo": "62"
 },
 {
  "id": "537",
  "ini": "お",
  "name": "落っことしパズル とんじゃん?",
  "maker": "ジャレコ",
  "releaseDate": "1989/9/29",
  "price": "5600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "538",
  "ini": "こ",
  "name": "甲子園",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1989/10/6",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "539",
  "ini": "わ",
  "name": "ワールドスーパーテニス",
  "maker": "アスミック",
  "releaseDate": "1989/10/13",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "540",
  "ini": "う",
  "name": "ヴィナス戦記",
  "maker": "バリエ",
  "releaseDate": "1989/10/14",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "541",
  "ini": "ほ",
  "name": "北斗の拳3 新世紀創造 凄拳列伝",
  "maker": "東映動画",
  "releaseDate": "1989/10/19",
  "price": "6200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "542",
  "ini": "そ",
  "name": "ソフトボール天国",
  "maker": "トンキンハウス（東京書籍）",
  "releaseDate": "1989/10/27",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "543",
  "ini": "と",
  "name": "ドラゴンボール3 悟空伝",
  "maker": "バンダイ",
  "releaseDate": "1989/10/27",
  "price": "6800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "544",
  "ini": "め",
  "name": "メジャーリーグ",
  "maker": "アイレム",
  "releaseDate": "1989/10/27",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "545",
  "ini": "ち",
  "name": "超人ウルトラベースボール",
  "maker": "カルチャーブレーン",
  "releaseDate": "1989/10/27",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "546",
  "ini": "た",
  "name": "田代まさしのプリンセスがいっぱい",
  "maker": "EPIC・ソニー",
  "releaseDate": "1989/10/27",
  "price": "5500",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "547",
  "ini": "ま",
  "name": "麻雀大会",
  "maker": "光栄",
  "releaseDate": "1989/10/31",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "548",
  "ini": "こ",
  "name": "御存知 弥次喜多珍道中",
  "maker": "HAL研究所",
  "releaseDate": "1989/11/7",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "549",
  "ini": "ゆ",
  "name": "U.S.チャンピオンシップ V'BALL　",
  "maker": "テクノスジャパン",
  "releaseDate": "1989/11/10",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "31"
 },
 {
  "id": "550",
  "ini": "た",
  "name": "ダンジョン＆マジック Swords of Element",
  "maker": "ナツメ",
  "releaseDate": "1989/11/10",
  "price": "6500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "551",
  "ini": "け",
  "name": "激突 四駆バトル",
  "maker": "アイレム",
  "releaseDate": "1989/11/17",
  "price": "4800",
  "genre": "レース",
  "icNo": "61"
 },
 {
  "id": "552",
  "ini": "に",
  "name": "忍者COP サイゾウ",
  "maker": "九娯貿易",
  "releaseDate": "1989/11/17",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "553",
  "ini": "こ",
  "name": "コズミックイプシロン",
  "maker": "アスミック",
  "releaseDate": "1989/11/24",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "554",
  "ini": "へ",
  "name": "ベガスコネクション カジノから愛をこめて",
  "maker": "シグマ商事",
  "releaseDate": "1989/11/24",
  "price": "9600",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "555",
  "ini": "て",
  "name": "寺尾のどすこい大相撲",
  "maker": "ジャレコ",
  "releaseDate": "1989/11/24",
  "price": "5900",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "556",
  "ini": "こ",
  "name": "ゴルフッ子オープン",
  "maker": "タイトー",
  "releaseDate": "1989/11/25",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "557",
  "ini": "す",
  "name": "スクウェアのトムソーヤ",
  "maker": "スクウェア",
  "releaseDate": "1989/11/30",
  "price": "6500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "558",
  "ini": "こ",
  "name": "コンフリクト",
  "maker": "ビック東海",
  "releaseDate": "1989/12/1",
  "price": "6200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "559",
  "ini": "た",
  "name": "TAO 道",
  "maker": "バップ",
  "releaseDate": "1989/12/1",
  "price": "5500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "560",
  "ini": "ほ",
  "name": "ホステージ",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1989/12/1",
  "price": "5900",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "561",
  "ini": "み",
  "name": "未来戦史ライオス",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/12/1",
  "price": "6300",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "562",
  "ini": "お",
  "name": "おそ松くん バック・トゥ・ザ・ミーの出っ歯の巻",
  "maker": "バンダイ",
  "releaseDate": "1989/12/8",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "563",
  "ini": "す",
  "name": "スーパーモグラたたき!! ぽっくんモグラー",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1989/12/8",
  "price": "6900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "564",
  "ini": "た",
  "name": "タイトー チェイスH.Q.",
  "maker": "タイトー",
  "releaseDate": "1989/12/8",
  "price": "5900",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "565",
  "ini": "し",
  "name": "新・里見八犬伝 光と闇の戦い",
  "maker": "東映動画",
  "releaseDate": "1989/12/8",
  "price": "6200",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "566",
  "ini": "せ",
  "name": "星霊狩り",
  "maker": "ハドソン",
  "releaseDate": "1989/12/8",
  "price": "5800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "567",
  "ini": "す",
  "name": "SUPER STAR プロレスリング",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/12/9",
  "price": "5900",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "568",
  "ini": "あ",
  "name": "アバドックス",
  "maker": "ナツメ",
  "releaseDate": "1989/12/15",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "569",
  "ini": "か",
  "name": "かってにシロクマ もりをすくえのまき",
  "maker": "CBS・ソニー",
  "releaseDate": "1989/12/15",
  "price": "5900",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "570",
  "ini": "す",
  "name": "スウィートホーム",
  "maker": "カプコン",
  "releaseDate": "1989/12/15",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "571",
  "ini": "と",
  "name": "トップガン デュアルファイターズ",
  "maker": "コナミ",
  "releaseDate": "1989/12/15",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "572",
  "ini": "ひ",
  "name": "ピンボールクエスト",
  "maker": "ジャレコ",
  "releaseDate": "1989/12/15",
  "price": "5600",
  "genre": "テーブル",
  "icNo": "91"
 },
 {
  "id": "573",
  "ini": "ふ",
  "name": "ファミコン 野球盤",
  "maker": "エポック社",
  "releaseDate": "1989/12/15",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "574",
  "ini": "ふ",
  "name": "フリップル",
  "maker": "タイトー",
  "releaseDate": "1989/12/15",
  "price": "3900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "575",
  "ini": "け",
  "name": "激闘スタジアム!!",
  "maker": "テクモ",
  "releaseDate": "1989/12/15",
  "price": "5500",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "576",
  "ini": "し",
  "name": "神仙伝",
  "maker": "アイレム",
  "releaseDate": "1989/12/15",
  "price": "6300",
  "genre": "RPG",
  "icNo": "62"
 },
 {
  "id": "577",
  "ini": "あ",
  "name": "I LOVE ソフトボール",
  "maker": "ココナッツジャパン",
  "releaseDate": "1989/12/19",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "12"
 },
 {
  "id": "578",
  "ini": "え",
  "name": "エモやんの10倍プロ野球 セリーグ編",
  "maker": "ヘクト",
  "releaseDate": "1989/12/19",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "579",
  "ini": "く",
  "name": "クォーターバック スクランブル",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/12/19",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "54"
 },
 {
  "id": "580",
  "ini": "ふ",
  "name": "ファミスタ'90",
  "maker": "ナムコ",
  "releaseDate": "1989/12/19",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "581",
  "ini": "さ",
  "name": "ザ・マネーゲームⅡ 兜町の奇跡",
  "maker": "ソフエル",
  "releaseDate": "1989/12/20",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "582",
  "ini": "さ",
  "name": "ザ・ロード・オブ・キング",
  "maker": "ジャレコ",
  "releaseDate": "1989/12/21",
  "price": "5600",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "583",
  "ini": "て",
  "name": "TM NETWORK LIVE IN POWER BOWL",
  "maker": "EPIC・ソニー",
  "releaseDate": "1989/12/22",
  "price": "6200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "584",
  "ini": "は",
  "name": "バットマン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1989/12/22",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "585",
  "ini": "ま",
  "name": "マッピーキッズ",
  "maker": "ナムコ",
  "releaseDate": "1989/12/22",
  "price": "5800",
  "genre": "アクション",
  "icNo": "94"
 },
 {
  "id": "586",
  "ini": "あ",
  "name": "悪魔城伝説",
  "maker": "コナミ",
  "releaseDate": "1989/12/22",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "587",
  "ini": "た",
  "name": "双截龍Ⅱ The Revenge",
  "maker": "テクノスジャパン",
  "releaseDate": "1989/12/22",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "588",
  "ini": "へ",
  "name": "ヘラクレスの栄光Ⅱ タイタンの滅亡",
  "maker": "データイースト",
  "releaseDate": "1989/12/23",
  "price": "5900",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "589",
  "ini": "ち",
  "name": "中国雀士ストーリー 東風",
  "maker": "ナツメ",
  "releaseDate": "1989/12/23",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "590",
  "ini": "め",
  "name": "名門！多古西応援団",
  "maker": "アスミック",
  "releaseDate": "1989/12/25",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "10"
 },
 {
  "id": "591",
  "ini": "す",
  "name": "スーパーラグビー",
  "maker": "T.S.S.",
  "releaseDate": "1989/12/27",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "592",
  "ini": "か",
  "name": "がんばれゴエモン外伝　きえた黄金キセル",
  "maker": "コナミ",
  "releaseDate": "1990/1/5",
  "price": "6980",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "593",
  "ini": "あ",
  "name": "アドベンチャーズ オブ ロロ",
  "maker": "HAL研究所",
  "releaseDate": "1990/1/6",
  "price": "4900",
  "genre": "パズル",
  "icNo": "51"
 },
 {
  "id": "594",
  "ini": "い",
  "name": "囲碁名鑑",
  "maker": "青木書店",
  "releaseDate": "1990/1/10",
  "price": "9680",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "595",
  "ini": "わ",
  "name": "わんぱくダック夢冒険",
  "maker": "カプコン",
  "releaseDate": "1990/1/26",
  "price": "5800",
  "genre": "アクション",
  "icNo": "32"
 },
 {
  "id": "596",
  "ini": "き",
  "name": "キャデラック",
  "maker": "ヘクト",
  "releaseDate": "1990/2/2",
  "price": "4900",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "597",
  "ini": "す",
  "name": "SUPER魂斗羅",
  "maker": "コナミ",
  "releaseDate": "1990/2/2",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "598",
  "ini": "の",
  "name": "信長の野望 戦国群雄伝",
  "maker": "光栄",
  "releaseDate": "1990/2/3",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "599",
  "ini": "も",
  "name": "もっともあぶない刑事",
  "maker": "東映動画",
  "releaseDate": "1990/2/6",
  "price": "5300",
  "genre": "アクション",
  "icNo": "11"
 },
 {
  "id": "600",
  "ini": "と",
  "name": "ドラゴンクエストIV 導かれし者たち",
  "maker": "エニックス",
  "releaseDate": "1990/2/11",
  "price": "8500",
  "genre": "RPG",
  "icNo": "11"
 },
 {
  "id": "601",
  "ini": "あ",
  "name": "アークティック",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/2/23",
  "price": "5500",
  "genre": "パズル",
  "icNo": "54"
 },
 {
  "id": "602",
  "ini": "き",
  "name": "キテレツ大百科",
  "maker": "エポック社",
  "releaseDate": "1990/2/23",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "603",
  "ini": "に",
  "name": "人間兵器 デッドフォックス",
  "maker": "カプコン",
  "releaseDate": "1990/2/23",
  "price": "5800",
  "genre": "アクション",
  "icNo": "62"
 },
 {
  "id": "604",
  "ini": "あ",
  "name": "悪魔くん 魔界の罠",
  "maker": "バンダイ",
  "releaseDate": "1990/2/24",
  "price": "5800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "605",
  "ini": "と",
  "name": "ドラゴンユニット",
  "maker": "アテナ",
  "releaseDate": "1990/2/27",
  "price": "5800",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "606",
  "ini": "に",
  "name": "西村京太郎ミステリー スーパーエクスプレス殺人事件",
  "maker": "アイレム",
  "releaseDate": "1990/3/2",
  "price": "6500",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "607",
  "ini": "ふ",
  "name": "プレジデントの選択",
  "maker": "ホット・ビィ",
  "releaseDate": "1990/3/2",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "61"
 },
 {
  "id": "608",
  "ini": "へ",
  "name": "ヘビーバレル",
  "maker": "データイースト",
  "releaseDate": "1990/3/2",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "609",
  "ini": "う",
  "name": "ウィザードリィⅢ ダイヤモンドの騎士",
  "maker": "アスキー",
  "releaseDate": "1990/3/9",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "610",
  "ini": "と",
  "name": "ドンドコドン",
  "maker": "タイトー",
  "releaseDate": "1990/3/9",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "611",
  "ini": "も",
  "name": "モアイくん",
  "maker": "コナミ",
  "releaseDate": "1990/3/9",
  "price": "4800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "612",
  "ini": "い",
  "name": "イメージファイト",
  "maker": "アイレム",
  "releaseDate": "1990/3/16",
  "price": "6500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "613",
  "ini": "か",
  "name": "仮面の忍者 花丸",
  "maker": "カプコン",
  "releaseDate": "1990/3/16",
  "price": "5800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "614",
  "ini": "い",
  "name": "怒Ⅲ",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1990/3/16",
  "price": "5600",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "615",
  "ini": "さ",
  "name": "サンサーラ・ナーガ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/3/23",
  "price": "7500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "616",
  "ini": "は",
  "name": "バルダーダッシュ",
  "maker": "データイースト",
  "releaseDate": "1990/3/23",
  "price": "4900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "617",
  "ini": "ま",
  "name": "魔神英雄伝ワタル外伝",
  "maker": "ハドソン",
  "releaseDate": "1990/3/23",
  "price": "6800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "618",
  "ini": "は",
  "name": "パワーサッカー",
  "maker": "徳間書店",
  "releaseDate": "1990/3/30",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "619",
  "ini": "へ",
  "name": "ベストプレープロ野球Ⅱ",
  "maker": "アスキー",
  "releaseDate": "1990/3/30",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "620",
  "ini": "い",
  "name": "井崎脩五郎の競馬必勝学",
  "maker": "イマジニア",
  "releaseDate": "1990/3/30",
  "price": "9800",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "621",
  "ini": "も",
  "name": "魍魎戦記 MADARA",
  "maker": "コナミ",
  "releaseDate": "1990/3/30",
  "price": "8500",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "622",
  "ini": "て",
  "name": "ディープダンジョンⅣ 黒の妖術師",
  "maker": "アスミック",
  "releaseDate": "1990/4/6",
  "price": "6500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "623",
  "ini": "て",
  "name": "デジタル・デビル物語 女神転生Ⅱ",
  "maker": "ナムコ",
  "releaseDate": "1990/4/6",
  "price": "7800",
  "genre": "RPG",
  "icNo": "94"
 },
 {
  "id": "624",
  "ini": "に",
  "name": "忍者龍剣伝Ⅱ 暗黒の邪神剣",
  "maker": "テクモ",
  "releaseDate": "1990/4/6",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "625",
  "ini": "う",
  "name": "ウルトラマン倶楽部2 帰ってきたウルトラマン倶楽部",
  "maker": "バンダイ",
  "releaseDate": "1990/4/7",
  "price": "6000",
  "genre": "RPG",
  "icNo": "63"
 },
 {
  "id": "626",
  "ini": "か",
  "name": "ガンヘッド 新たなる戦い",
  "maker": "バリエ",
  "releaseDate": "1990/4/13",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "5"
 },
 {
  "id": "627",
  "ini": "く",
  "name": "クォース",
  "maker": "コナミ",
  "releaseDate": "1990/4/13",
  "price": "4900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "628",
  "ini": "こ",
  "name": "ゴッドスレイヤー はるか天空のソナタ",
  "maker": "SNK",
  "releaseDate": "1990/4/13",
  "price": "7600",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "629",
  "ini": "え",
  "name": "SDバトル大相撲 平成ヒーロー場所",
  "maker": "バンプレスト",
  "releaseDate": "1990/4/20",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "630",
  "ini": "は",
  "name": "パワーブレイザー",
  "maker": "タイトー",
  "releaseDate": "1990/4/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "631",
  "ini": "ふ",
  "name": "ファイアーエムブレム 暗黒竜と光の剣",
  "maker": "任天堂",
  "releaseDate": "1990/4/20",
  "price": "6000",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "632",
  "ini": "く",
  "name": "黒鉄ヒロシの予想大好き！勝馬伝説",
  "maker": "日本物産",
  "releaseDate": "1990/4/20",
  "price": "8000",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "633",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅢ",
  "maker": "スクウェア",
  "releaseDate": "1990/4/27",
  "price": "8400",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "634",
  "ini": "か",
  "name": "影狼伝説",
  "maker": "ピクセル",
  "releaseDate": "1990/5/11",
  "price": "6200",
  "genre": "RPG",
  "icNo": "51"
 },
 {
  "id": "635",
  "ini": "き",
  "name": "キャッスルクエスト",
  "maker": "ハドソン",
  "releaseDate": "1990/5/18",
  "price": "5800",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "636",
  "ini": "ね",
  "name": "熱血高校ドッジボール部 サッカー編",
  "maker": "テクノスジャパン",
  "releaseDate": "1990/5/18",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "637",
  "ini": "い",
  "name": "イースⅡ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/5/25",
  "price": "7500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "638",
  "ini": "は",
  "name": "馬券必勝学 ゲートイン",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1990/5/25",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "61"
 },
 {
  "id": "639",
  "ini": "し",
  "name": "じゃじゃ丸撃魔伝 幻の金魔城",
  "maker": "ジャレコ",
  "releaseDate": "1990/5/29",
  "price": "5900",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "640",
  "ini": "ち",
  "name": "チップとデールの大作戦",
  "maker": "カプコン",
  "releaseDate": "1990/6/8",
  "price": "5800",
  "genre": "アクション",
  "icNo": "64"
 },
 {
  "id": "641",
  "ini": "た",
  "name": "ダッシュ野郎",
  "maker": "ビスコ",
  "releaseDate": "1990/6/15",
  "price": "5800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "642",
  "ini": "は",
  "name": "バトルフリート",
  "maker": "ナムコ",
  "releaseDate": "1990/6/22",
  "price": "5900",
  "genre": "シミュレーション",
  "icNo": "94"
 },
 {
  "id": "643",
  "ini": "ふ",
  "name": "ファイナルミッション",
  "maker": "ナツメ",
  "releaseDate": "1990/6/22",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "644",
  "ini": "す",
  "name": "水滸伝 天命の誓い",
  "maker": "光栄",
  "releaseDate": "1990/6/25",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "645",
  "ini": "も",
  "name": "燃えろ!! 柔道WARRIORS",
  "maker": "ジャレコ",
  "releaseDate": "1990/6/29",
  "price": "5600",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "646",
  "ini": "め",
  "name": "迷宮島",
  "maker": "アイレム",
  "releaseDate": "1990/6/29",
  "price": "5800",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "647",
  "ini": "は",
  "name": "ハットリス",
  "maker": "BPS",
  "releaseDate": "1990/7/6",
  "price": "5500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "648",
  "ini": "は",
  "name": "パラメデス",
  "maker": "ホット・ビィ",
  "releaseDate": "1990/7/6",
  "price": "5500",
  "genre": "パズル",
  "icNo": "21"
 },
 {
  "id": "649",
  "ini": "ひ",
  "name": "飛龍の拳Ⅲ 五人の龍戦士",
  "maker": "カルチャーブレーン",
  "releaseDate": "1990/7/6",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "650",
  "ini": "え",
  "name": "SDヒーロー総決戦 倒せ！悪の軍団",
  "maker": "バンプレスト",
  "releaseDate": "1990/7/7",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "651",
  "ini": "う",
  "name": "wit's",
  "maker": "アテナ",
  "releaseDate": "1990/7/13",
  "price": "5800",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "652",
  "ini": "き",
  "name": "キャプテン翼Ⅱ スーパーストライカー",
  "maker": "テクモ",
  "releaseDate": "1990/7/20",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "10"
 },
 {
  "id": "653",
  "ini": "そ",
  "name": "ソルスティス 三次元迷宮の狂獣",
  "maker": "EPIC・ソニー",
  "releaseDate": "1990/7/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "40"
 },
 {
  "id": "654",
  "ini": "に",
  "name": "ニチブツマージャンⅢ マージャンGメン",
  "maker": "日本物産",
  "releaseDate": "1990/7/20",
  "price": "6400",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "655",
  "ini": "ふ",
  "name": "ブライファイター",
  "maker": "タイトー",
  "releaseDate": "1990/7/20",
  "price": "5900",
  "genre": "シューティング",
  "icNo": "43"
 },
 {
  "id": "656",
  "ini": "し",
  "name": "獣王記",
  "maker": "アスミック",
  "releaseDate": "1990/7/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "657",
  "ini": "さ",
  "name": "THE・ペナントリーグ ホームランナイター'90",
  "maker": "データイースト",
  "releaseDate": "1990/7/24",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "64"
 },
 {
  "id": "658",
  "ini": "こ",
  "name": "ゴルゴ13 第二章 イカロスの謎",
  "maker": "ビック東海",
  "releaseDate": "1990/7/27",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "659",
  "ini": "す",
  "name": "STED 遺跡惑星の野望",
  "maker": "ケイ・アミューズメントリース",
  "releaseDate": "1990/7/27",
  "price": "5900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "660",
  "ini": "と",
  "name": "ドクターマリオ",
  "maker": "任天堂",
  "releaseDate": "1990/7/27",
  "price": "4900",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "661",
  "ini": "ひ",
  "name": "ビックリマンワールド 激突聖戦士",
  "maker": "ハドソン",
  "releaseDate": "1990/7/27",
  "price": "6800",
  "genre": "RPG",
  "icNo": "30"
 },
 {
  "id": "662",
  "ini": "も",
  "name": "燃えプロ！'90 感動編",
  "maker": "ジャレコ",
  "releaseDate": "1990/7/27",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "663",
  "ini": "ま",
  "name": "Might and Magic",
  "maker": "学習研究社",
  "releaseDate": "1990/7/31",
  "price": "8500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "664",
  "ini": "け",
  "name": "ゲームパーティー",
  "maker": "ココナッツジャパン",
  "releaseDate": "1990/8/3",
  "price": "6500",
  "genre": "その他",
  "icNo": "63"
 },
 {
  "id": "665",
  "ini": "は",
  "name": "ハッピーバースディ・バックス",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1990/8/3",
  "price": "5900",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "666",
  "ini": "に",
  "name": "2010 ストリートファイター",
  "maker": "カプコン",
  "releaseDate": "1990/8/8",
  "price": "5800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "667",
  "ini": "に",
  "name": "忍者らホイ！",
  "maker": "アスキー",
  "releaseDate": "1990/8/8",
  "price": "7800",
  "genre": "RPG",
  "icNo": "94"
 },
 {
  "id": "668",
  "ini": "や",
  "name": "闇の仕事人 KAGE",
  "maker": "ナツメ",
  "releaseDate": "1990/8/10",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "669",
  "ini": "た",
  "name": "TITAN",
  "maker": "ソフエル",
  "releaseDate": "1990/8/10",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "670",
  "ini": "と",
  "name": "ドラゴンファイター",
  "maker": "トーワチキ",
  "releaseDate": "1990/8/10",
  "price": "5700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "671",
  "ini": "は",
  "name": "ぱられるワールド",
  "maker": "バリエ",
  "releaseDate": "1990/8/10",
  "price": "5980",
  "genre": "パズル",
  "icNo": "13"
 },
 {
  "id": "672",
  "ini": "ひ",
  "name": "ピーパータイム",
  "maker": "サンリツ電気",
  "releaseDate": "1990/8/10",
  "price": "4900",
  "genre": "パズル",
  "icNo": "30"
 },
 {
  "id": "673",
  "ini": "ら",
  "name": "ラフワールド",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/8/10",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "674",
  "ini": "か",
  "name": "川のぬし釣り",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/8/10",
  "price": "6300",
  "genre": "RPG",
  "icNo": "53"
 },
 {
  "id": "675",
  "ini": "に",
  "name": "日本一の名監督",
  "maker": "アスミック",
  "releaseDate": "1990/8/10",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "20"
 },
 {
  "id": "676",
  "ini": "え",
  "name": "SDガンダム外伝 ナイトガンダム物語",
  "maker": "バンダイ",
  "releaseDate": "1990/8/11",
  "price": "6800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "677",
  "ini": "ふ",
  "name": "ブロディアランド",
  "maker": "トンキンハウス（東京書籍）",
  "releaseDate": "1990/8/11",
  "price": "5600",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "678",
  "ini": "く",
  "name": "孔雀王Ⅱ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/8/21",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "679",
  "ini": "し",
  "name": "上海Ⅱ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/8/24",
  "price": "5500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "680",
  "ini": "ま",
  "name": "魔天童子",
  "maker": "クエスト",
  "releaseDate": "1990/8/24",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "681",
  "ini": "し",
  "name": "雀豪",
  "maker": "ビクター音楽産業",
  "releaseDate": "1990/8/30",
  "price": "6700",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "682",
  "ini": "た",
  "name": "ダンジョンキッド",
  "maker": "クエスト",
  "releaseDate": "1990/8/31",
  "price": "6500",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "683",
  "ini": "う",
  "name": "宇宙警備隊 SDF",
  "maker": "HAL研究所",
  "releaseDate": "1990/9/7",
  "price": "6900",
  "genre": "シューティング",
  "icNo": "91"
 },
 {
  "id": "684",
  "ini": "え",
  "name": "SD戦国武将列伝 烈火のごとく天下を盗れ！",
  "maker": "バンプレスト",
  "releaseDate": "1990/9/8",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "10"
 },
 {
  "id": "685",
  "ini": "わ",
  "name": "ワールドボクシング",
  "maker": "T.S.S.",
  "releaseDate": "1990/9/8",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "686",
  "ini": "と",
  "name": "ドラえもん ギガゾンビの逆襲",
  "maker": "エポック社",
  "releaseDate": "1990/9/14",
  "price": "6800",
  "genre": "RPG",
  "icNo": "54"
 },
 {
  "id": "687",
  "ini": "り",
  "name": "リトルマジック",
  "maker": "データイースト",
  "releaseDate": "1990/9/14",
  "price": "6300",
  "genre": "シミュレーション",
  "icNo": "64"
 },
 {
  "id": "688",
  "ini": "は",
  "name": "爆笑！スターものまね四天王",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/9/14",
  "price": "6200",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "689",
  "ini": "い",
  "name": "維新の嵐",
  "maker": "光栄",
  "releaseDate": "1990/9/15",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "690",
  "ini": "い",
  "name": "インセクターX",
  "maker": "タイトー",
  "releaseDate": "1990/9/21",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "691",
  "ini": "た",
  "name": "田村光昭 麻雀ゼミナール",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/9/21",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "692",
  "ini": "の",
  "name": "ノース&サウス わくわく南北戦争",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1990/9/21",
  "price": "6200",
  "genre": "シミュレーション",
  "icNo": "51"
 },
 {
  "id": "693",
  "ini": "ま",
  "name": "マジックジョン",
  "maker": "ジャレコ",
  "releaseDate": "1990/9/28",
  "price": "5600",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "694",
  "ini": "ろ",
  "name": "ロックマン3 Dr.ワイリーの最期?",
  "maker": "カプコン",
  "releaseDate": "1990/9/28",
  "price": "6500",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "695",
  "ini": "た",
  "name": "探偵 神宮寺三郎 時の過ぎゆくままに・・・",
  "maker": "データイースト",
  "releaseDate": "1990/9/28",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "696",
  "ini": "か",
  "name": "ガンナック",
  "maker": "トンキンハウス（東京書籍）",
  "releaseDate": "1990/10/5",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "697",
  "ini": "た",
  "name": "ダウンタウン熱血行進曲 それゆけ大運動会",
  "maker": "テクノスジャパン",
  "releaseDate": "1990/10/12",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "698",
  "ini": "ふ",
  "name": "ブラッディ・ウァリアーズ シャンゴーの逆襲",
  "maker": "東映動画",
  "releaseDate": "1990/10/19",
  "price": "6200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "699",
  "ini": "ま",
  "name": "まじゃべんちゃー 麻雀戦記",
  "maker": "徳間書店",
  "releaseDate": "1990/10/19",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "12"
 },
 {
  "id": "700",
  "ini": "あ",
  "name": "悪魔城すぺしゃる ぼくドラキュラくん",
  "maker": "コナミ",
  "releaseDate": "1990/10/19",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "701",
  "ini": "し",
  "name": "シャッフルパックカフェ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/10/21",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "40"
 },
 {
  "id": "702",
  "ini": "お",
  "name": "おえかキッズ アンパンマンとおえかきしよう!!",
  "maker": "バンダイ",
  "releaseDate": "1990/10/25",
  "price": "13800",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "703",
  "ini": "あ",
  "name": "アストロファング",
  "maker": "A-WAVE",
  "releaseDate": "1990/10/26",
  "price": "6700",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "704",
  "ini": "な",
  "name": "なんてったって!! ベースボール",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/10/26",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "705",
  "ini": "み",
  "name": "水島新司の大甲子園",
  "maker": "カプコン",
  "releaseDate": "1990/10/26",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "61"
 },
 {
  "id": "706",
  "ini": "は",
  "name": "パチ夫くん3 帰ってきたパチ夫くん",
  "maker": "ココナッツジャパン",
  "releaseDate": "1990/10/26",
  "price": "7980",
  "genre": "テーブル",
  "icNo": "31"
 },
 {
  "id": "707",
  "ini": "と",
  "name": "ドラゴンボールZ 強襲！サイヤ人",
  "maker": "バンダイ",
  "releaseDate": "1990/10/27",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "708",
  "ini": "く",
  "name": "クロスファイヤー",
  "maker": "九娯貿易",
  "releaseDate": "1990/11/2",
  "price": "6200",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "709",
  "ini": "さ",
  "name": "三國志Ⅱ",
  "maker": "光栄",
  "releaseDate": "1990/11/2",
  "price": "14800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "710",
  "ini": "や",
  "name": "山村美紗サスペンス 京都財テク殺人事件",
  "maker": "ヘクト",
  "releaseDate": "1990/11/2",
  "price": "8500",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "711",
  "ini": "ら",
  "name": "ラストハルマゲドン",
  "maker": "ユタカ",
  "releaseDate": "1990/11/10",
  "price": "9800",
  "genre": "アドベンチャー",
  "icNo": "4"
 },
 {
  "id": "712",
  "ini": "て",
  "name": "テクモボウル",
  "maker": "テクモ",
  "releaseDate": "1990/11/13",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "713",
  "ini": "さ",
  "name": "サンリオカーニバル",
  "maker": "キャラクターソフト",
  "releaseDate": "1990/11/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "714",
  "ini": "ふ",
  "name": "ふしぎなブロビー ブロバニアの危機",
  "maker": "ジャレコ",
  "releaseDate": "1990/11/29",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "715",
  "ini": "は",
  "name": "パロディウスだ！",
  "maker": "コナミ",
  "releaseDate": "1990/11/30",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "716",
  "ini": "め",
  "name": "迷宮の達人 大迷路",
  "maker": "エポック社",
  "releaseDate": "1990/11/30",
  "price": "5800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "717",
  "ini": "て",
  "name": "ティーンエイジ・ミュータント・ニンジャ・タートルズ",
  "maker": "コナミ",
  "releaseDate": "1990/12/7",
  "price": "7000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "718",
  "ini": "き",
  "name": "ぎゅわんぶらあ自己中心派2",
  "maker": "アスミック",
  "releaseDate": "1990/12/7",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "719",
  "ini": "た",
  "name": "TIMES OF LORE　失われたメダリオン",
  "maker": "東宝",
  "releaseDate": "1990/12/7",
  "price": "5300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "720",
  "ini": "て",
  "name": "テクモ ワールドカップサッカー",
  "maker": "テクモ",
  "releaseDate": "1990/12/7",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "721",
  "ini": "は",
  "name": "パジャマヒーロー NEMO",
  "maker": "カプコン",
  "releaseDate": "1990/12/7",
  "price": "6200",
  "genre": "アクション",
  "icNo": "43"
 },
 {
  "id": "722",
  "ini": "く",
  "name": "グレートボクシング RUSH・UP",
  "maker": "ビスコ",
  "releaseDate": "1990/12/7",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "3"
 },
 {
  "id": "723",
  "ini": "さ",
  "name": "西遊記ワールドⅡ 天上界の魔神",
  "maker": "ジャレコ",
  "releaseDate": "1990/12/7",
  "price": "5600",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "724",
  "ini": "ち",
  "name": "千代の富士の大銀杏",
  "maker": "フェイス",
  "releaseDate": "1990/12/7",
  "price": "5800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "725",
  "ini": "ふ",
  "name": "ファミコン トップマネジメント",
  "maker": "光栄",
  "releaseDate": "1990/12/12",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "726",
  "ini": "へ",
  "name": "ベストプレープロ野球'90",
  "maker": "アスキー",
  "releaseDate": "1990/12/13",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "727",
  "ini": "く",
  "name": "KLAX",
  "maker": "ハドソン",
  "releaseDate": "1990/12/14",
  "price": "5800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "728",
  "ini": "く",
  "name": "グレムリン2 新・種・誕・生",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1990/12/14",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "729",
  "ini": "に",
  "name": "忍者クルセイダーズ 龍牙",
  "maker": "サミー工業",
  "releaseDate": "1990/12/14",
  "price": "5500",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "730",
  "ini": "ま",
  "name": "まじかるキッズどろぴー",
  "maker": "ビック東海",
  "releaseDate": "1990/12/14",
  "price": "6000",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "731",
  "ini": "め",
  "name": "メタルフレーム サイバスター",
  "maker": "ジャレコ",
  "releaseDate": "1990/12/14",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "732",
  "ini": "ひ",
  "name": "必殺仕事人",
  "maker": "バンプレスト",
  "releaseDate": "1990/12/14",
  "price": "7600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "733",
  "ini": "あ",
  "name": "暴れん坊天狗",
  "maker": "メルダック",
  "releaseDate": "1990/12/14",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "734",
  "ini": "し",
  "name": "ジャンピンキッド ジャックと豆の木ものがたり",
  "maker": "アスミック",
  "releaseDate": "1990/12/19",
  "price": "5800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "735",
  "ini": "は",
  "name": "パーマン",
  "maker": "アイレム",
  "releaseDate": "1990/12/14",
  "price": "5500",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "736",
  "ini": "わ",
  "name": "ワギャンランド2",
  "maker": "ナムコ",
  "releaseDate": "1990/12/14",
  "price": "5800",
  "genre": "アクション",
  "icNo": "94"
 },
 {
  "id": "737",
  "ini": "は",
  "name": "バトルスタジアム 選抜プロ野球",
  "maker": "アイ・ジーエス",
  "releaseDate": "1990/12/20",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "738",
  "ini": "そ",
  "name": "ゾイド 黙示録",
  "maker": "トミー",
  "releaseDate": "1990/12/21",
  "price": "6700",
  "genre": "シミュレーション",
  "icNo": "0"
 },
 {
  "id": "739",
  "ini": "そ",
  "name": "ソードマスター",
  "maker": "アテナ",
  "releaseDate": "1990/12/21",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "740",
  "ini": "は",
  "name": "バーズテイル 知られざる物語",
  "maker": "ポニーキャニオン",
  "releaseDate": "1990/12/21",
  "price": "6800",
  "genre": "RPG",
  "icNo": "3"
 },
 {
  "id": "741",
  "ini": "ふ",
  "name": "ファミスタ'91",
  "maker": "ナムコ",
  "releaseDate": "1990/12/21",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "742",
  "ini": "た",
  "name": "大怪獣デブラス",
  "maker": "データイースト",
  "releaseDate": "1990/12/21",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "10"
 },
 {
  "id": "743",
  "ini": "し",
  "name": "地獄・極楽丸",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1990/12/21",
  "price": "6200",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "744",
  "ini": "え",
  "name": "SDガンダム ガチャポン戦士3 英雄戦記",
  "maker": "バンダイ",
  "releaseDate": "1990/12/22",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "745",
  "ini": "む",
  "name": "ムサシの冒険",
  "maker": "シグマ商事",
  "releaseDate": "1990/12/22",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "746",
  "ini": "に",
  "name": "NEW ゴーストバスターズ2",
  "maker": "HAL研究所",
  "releaseDate": "1990/12/26",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "747",
  "ini": "あ",
  "name": "アドベンチャーズ　オブ　ロロⅡ",
  "maker": "HAL研究所",
  "releaseDate": "1990/12/26",
  "price": "5900",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "748",
  "ini": "は",
  "name": "爆笑　愛の劇場",
  "maker": "ココナッツジャパン",
  "releaseDate": "1990/12/29",
  "price": "7300",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "749",
  "ini": "し",
  "name": "じゅうべえくえすと",
  "maker": "ナムコ",
  "releaseDate": "1991/1/4",
  "price": "7800",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "750",
  "ini": "わ",
  "name": "ワイワイワールド2 SOS!! パセリ城",
  "maker": "コナミ",
  "releaseDate": "1991/1/5",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "751",
  "ini": "し",
  "name": "ジャッキーチェン",
  "maker": "ハドソン",
  "releaseDate": "1991/1/25",
  "price": "5800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "752",
  "ini": "と",
  "name": "ドラドラドラ",
  "maker": "ナツメ",
  "releaseDate": "1991/1/25",
  "price": "5800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "753",
  "ini": "ゆ",
  "name": "夢ペンギン物語",
  "maker": "コナミ",
  "releaseDate": "1991/1/25",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "754",
  "ini": "へ",
  "name": "ペーパーボーイ",
  "maker": "アルトロン",
  "releaseDate": "1991/1/30",
  "price": "5500",
  "genre": "アクション",
  "icNo": "64"
 },
 {
  "id": "755",
  "ini": "こ",
  "name": "ゴルフグランドスラム",
  "maker": "ヘクト",
  "releaseDate": "1991/1/31",
  "price": "9700",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "756",
  "ini": "た",
  "name": "ダークロード",
  "maker": "データイースト",
  "releaseDate": "1991/2/8",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "757",
  "ini": "ち",
  "name": "チャンピオンシップ ボーリング",
  "maker": "アテナ",
  "releaseDate": "1991/2/8",
  "price": "4980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "758",
  "ini": "あ",
  "name": "赤川次郎の幽霊列車",
  "maker": "キングレコード",
  "releaseDate": "1991/2/8",
  "price": "6800",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "759",
  "ini": "は",
  "name": "パラソルヘンべえ　おとぎの国は大さわぎ！",
  "maker": "エポック社",
  "releaseDate": "1991/2/15",
  "price": "6500",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "760",
  "ini": "ふ",
  "name": "ファミコン 将棋 竜王戦",
  "maker": "アイマックス",
  "releaseDate": "1991/2/15",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "761",
  "ini": "ま",
  "name": "マックス・ウォーリアー 惑星戒厳令",
  "maker": "バップ",
  "releaseDate": "1991/2/15",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "10"
 },
 {
  "id": "762",
  "ini": "み",
  "name": "ミニパット",
  "maker": "A-WAVE",
  "releaseDate": "1991/2/15",
  "price": "6700",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "763",
  "ini": "い",
  "name": "井出洋介名人の実践麻雀Ⅱ",
  "maker": "カプコン",
  "releaseDate": "1991/2/22",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "764",
  "ini": "た",
  "name": "双截龍Ⅲ The Rosetta Stone",
  "maker": "テクノスジャパン",
  "releaseDate": "1991/2/22",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "765",
  "ini": "に",
  "name": "虹のシルクロード ジグザグ冒険記",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/2/22",
  "price": "7500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "766",
  "ini": "く",
  "name": "グランドマスター",
  "maker": "バリエ",
  "releaseDate": "1991/2/26",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "767",
  "ini": "な",
  "name": "なんてったってベースボール!! 子ガメカセット OBオールスター編",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/2/28",
  "price": "2280",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "768",
  "ini": "す",
  "name": "スーパーチャイニーズ3",
  "maker": "カルチャーブレーン",
  "releaseDate": "1991/3/1",
  "price": "6300",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "769",
  "ini": "き",
  "name": "究極ハリキリスタジアムⅢ",
  "maker": "タイトー",
  "releaseDate": "1991/3/1",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "770",
  "ini": "あ",
  "name": "Advanced Dungeons & Dragons ヒーロー・オブ・ランス",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/3/8",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "771",
  "ini": "な",
  "name": "ナムコット麻雀Ⅲ マージャン天国",
  "maker": "ナムコ",
  "releaseDate": "1991/3/8",
  "price": "4900",
  "genre": "テーブル",
  "icNo": "94"
 },
 {
  "id": "772",
  "ini": "か",
  "name": "ガンサイト",
  "maker": "コナミ",
  "releaseDate": "1991/3/15",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "91"
 },
 {
  "id": "773",
  "ini": "ふ",
  "name": "ふしぎの海のナディア",
  "maker": "東宝",
  "releaseDate": "1991/3/15",
  "price": "6500",
  "genre": "シミュレーション",
  "icNo": "54"
 },
 {
  "id": "774",
  "ini": "た",
  "name": "大航海時代",
  "maker": "光栄",
  "releaseDate": "1991/3/15",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "775",
  "ini": "あ",
  "name": "Advanced Dungeons & Dragons ヒルズファー",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/3/21",
  "price": "7800",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "776",
  "ini": "い",
  "name": "いただきストリート　私のお店によってって",
  "maker": "アスキー",
  "releaseDate": "1991/3/21",
  "price": "6800",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "777",
  "ini": "し",
  "name": "シャドウブレイン",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/3/21",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "778",
  "ini": "ま",
  "name": "まじかる☆タルるートくん FANTASTIC WORLD!!",
  "maker": "バンダイ",
  "releaseDate": "1991/3/21",
  "price": "5800",
  "genre": "アクション",
  "icNo": "52"
 },
 {
  "id": "779",
  "ini": "は",
  "name": "爆笑!! 人生劇場2",
  "maker": "タイトー",
  "releaseDate": "1991/3/22",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "780",
  "ini": "お",
  "name": "おえかキッズ　アンパンマンのひらがなだいすき",
  "maker": "バンダイ",
  "releaseDate": "1991/3/26",
  "price": "6800",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "781",
  "ini": "け",
  "name": "けろけろけろっぴの大冒険",
  "maker": "キャラクターソフト",
  "releaseDate": "1991/3/29",
  "price": "3980",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "782",
  "ini": "に",
  "name": "忍者じゃじゃ丸 銀河大作戦",
  "maker": "ジャレコ",
  "releaseDate": "1991/3/29",
  "price": "5800",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "783",
  "ini": "ほ",
  "name": "北斗の拳4 七星覇拳伝 北斗神拳の彼方へ",
  "maker": "東映動画",
  "releaseDate": "1991/3/29",
  "price": "6500",
  "genre": "RPG",
  "icNo": "10"
 },
 {
  "id": "784",
  "ini": "ろ",
  "name": "ロボコップ2",
  "maker": "データイースト",
  "releaseDate": "1991/4/2",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "785",
  "ini": "に",
  "name": "ニューヨーク ニャンキーズ",
  "maker": "アトラス",
  "releaseDate": "1991/4/5",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "786",
  "ini": "お",
  "name": "おぼっちゃまくん",
  "maker": "テクモ",
  "releaseDate": "1991/4/5",
  "price": "6500",
  "genre": "その他",
  "icNo": "62"
 },
 {
  "id": "787",
  "ini": "て",
  "name": "天地を喰らうⅡ 諸葛孔明伝",
  "maker": "カプコン",
  "releaseDate": "1991/4/5",
  "price": "8500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "788",
  "ini": "こ",
  "name": "ゴルビーのパイプライン大作戦",
  "maker": "徳間書店",
  "releaseDate": "1991/4/12",
  "price": "4900",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "789",
  "ini": "つ",
  "name": "ツインイーグル",
  "maker": "ビスコ",
  "releaseDate": "1991/4/12",
  "price": "5800",
  "genre": "シューティング",
  "icNo": "60"
 },
 {
  "id": "790",
  "ini": "ふ",
  "name": "ファミリーブロック",
  "maker": "アテナ",
  "releaseDate": "1991/4/12",
  "price": "5500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "791",
  "ini": "ま",
  "name": "麻雀倶楽部永田町 総裁戦",
  "maker": "ヘクト",
  "releaseDate": "1991/4/25",
  "price": "9700",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "792",
  "ini": "か",
  "name": "GUN-DEC",
  "maker": "サミー工業",
  "releaseDate": "1991/4/26",
  "price": "6000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "793",
  "ini": "お",
  "name": "オーバーホライゾン",
  "maker": "ホット・ビィ",
  "releaseDate": "1991/4/26",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "794",
  "ini": "た",
  "name": "タイトーバスケットボール",
  "maker": "タイトー",
  "releaseDate": "1991/4/26",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "52"
 },
 {
  "id": "795",
  "ini": "ま",
  "name": "マジック・ダーツ",
  "maker": "セタ",
  "releaseDate": "1991/4/26",
  "price": "7700",
  "genre": "テーブル",
  "icNo": "60"
 },
 {
  "id": "796",
  "ini": "ら",
  "name": "ラグランジュポイント",
  "maker": "コナミ",
  "releaseDate": "1991/4/26",
  "price": "8500",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "797",
  "ini": "た",
  "name": "高橋名人の冒険島Ⅱ",
  "maker": "ハドソン",
  "releaseDate": "1991/4/26",
  "price": "5800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "798",
  "ini": "し",
  "name": "紫禁城",
  "maker": "東映動画",
  "releaseDate": "1991/4/26",
  "price": "5300",
  "genre": "パズル",
  "icNo": "54"
 },
 {
  "id": "799",
  "ini": "こ",
  "name": "ココロン",
  "maker": "タケル",
  "releaseDate": "1991/5/3",
  "price": "6500",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "800",
  "ini": "は",
  "name": "パラメデスⅡ",
  "maker": "ホット・ビィ",
  "releaseDate": "1991/5/17",
  "price": "5500",
  "genre": "パズル",
  "icNo": "44"
 },
 {
  "id": "801",
  "ini": "ふ",
  "name": "ファジカルファイター",
  "maker": "シグマ商事",
  "releaseDate": "1991/5/17",
  "price": "6200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "802",
  "ini": "ら",
  "name": "ランペルール",
  "maker": "光栄",
  "releaseDate": "1991/5/23",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "803",
  "ini": "て",
  "name": "テトラ・スター THE FIGHTER",
  "maker": "タイトー",
  "releaseDate": "1991/5/24",
  "price": "7400",
  "genre": "シューティング",
  "icNo": "64"
 },
 {
  "id": "804",
  "ini": "め",
  "name": "メタルマックス",
  "maker": "データイースト",
  "releaseDate": "1991/5/24",
  "price": "7800",
  "genre": "RPG",
  "icNo": "2"
 },
 {
  "id": "805",
  "ini": "え",
  "name": "エジプト",
  "maker": "ヒューマン",
  "releaseDate": "1991/5/31",
  "price": "3800",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "806",
  "ini": "な",
  "name": "なんてったってベースボール!! 子ガメカセット '91開幕編",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/5/31",
  "price": "2280",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "807",
  "ini": "に",
  "name": "忍者龍剣伝Ⅲ 黄泉の方舟",
  "maker": "テクモ",
  "releaseDate": "1991/6/21",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "808",
  "ini": "ひ",
  "name": "飛龍の拳スペシャル ファイティングウォーズ",
  "maker": "カルチャーブレーン",
  "releaseDate": "1991/6/21",
  "price": "6300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "809",
  "ini": "あ",
  "name": "Advanced Dungeons & Dragons プール・オブ・レイディアンス",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/6/28",
  "price": "9800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "810",
  "ini": "す",
  "name": "すごろクエスト ダイスの戦士たち",
  "maker": "テクノスジャパン",
  "releaseDate": "1991/6/28",
  "price": "6500",
  "genre": "その他",
  "icNo": "2"
 },
 {
  "id": "811",
  "ini": "つ",
  "name": "つっぱりウォーズ",
  "maker": "サミー工業",
  "releaseDate": "1991/6/28",
  "price": "6800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "812",
  "ini": "ほ",
  "name": "ボンバーマンⅡ",
  "maker": "ハドソン",
  "releaseDate": "1991/6/28",
  "price": "5800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "813",
  "ini": "ち",
  "name": "超人狼戦記 WARWOLF",
  "maker": "タカラ",
  "releaseDate": "1991/6/28",
  "price": "6500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "814",
  "ini": "し",
  "name": "新4人打ちマージャン 役満天国",
  "maker": "任天堂",
  "releaseDate": "1991/6/28",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "90"
 },
 {
  "id": "815",
  "ini": "へ",
  "name": "ベースボールファイター",
  "maker": "バップ",
  "releaseDate": "1991/7/5",
  "price": "7800",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "816",
  "ini": "い",
  "name": "囲碁指南'91",
  "maker": "ヘクト",
  "releaseDate": "1991/7/5",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "817",
  "ini": "し",
  "name": "JuJu伝説",
  "maker": "タイトー",
  "releaseDate": "1991/7/19",
  "price": "6800",
  "genre": "アクション",
  "icNo": "61"
 },
 {
  "id": "818",
  "ini": "き",
  "name": "キャッ党忍伝てやんでえ",
  "maker": "テクモ",
  "releaseDate": "1991/7/19",
  "price": "6500",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "819",
  "ini": "た",
  "name": "ダイ・ハード",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1991/7/19",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "820",
  "ini": "は",
  "name": "パズニック",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/7/19",
  "price": "4900",
  "genre": "パズル",
  "icNo": "30"
 },
 {
  "id": "821",
  "ini": "は",
  "name": "パチンコ大作戦",
  "maker": "ココナッツジャパン",
  "releaseDate": "1991/7/19",
  "price": "5900",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "822",
  "ini": "ふ",
  "name": "ファミリーサーキット'91",
  "maker": "ナムコ",
  "releaseDate": "1991/7/19",
  "price": "7800",
  "genre": "レース",
  "icNo": "50"
 },
 {
  "id": "823",
  "ini": "り",
  "name": "リトル・マーメイド 人魚姫",
  "maker": "カプコン",
  "releaseDate": "1991/7/19",
  "price": "5800",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "824",
  "ini": "た",
  "name": "ダウンタウンスペシャル くにおくんの時代劇だよ全員集合！",
  "maker": "テクノスジャパン",
  "releaseDate": "1991/7/26",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "825",
  "ini": "お",
  "name": "おたくの星座",
  "maker": "M&M",
  "releaseDate": "1991/7/31",
  "price": "6900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "826",
  "ini": "え",
  "name": "SD刑事ブレイダー",
  "maker": "タイトー",
  "releaseDate": "1991/8/2",
  "price": "6400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "827",
  "ini": "ろ",
  "name": "ロボッ子ウォーズ",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/8/2",
  "price": "6900",
  "genre": "シューティング",
  "icNo": "50"
 },
 {
  "id": "828",
  "ini": "す",
  "name": "スーパースプリント",
  "maker": "アルトロン",
  "releaseDate": "1991/8/3",
  "price": "6500",
  "genre": "レース",
  "icNo": "53"
 },
 {
  "id": "829",
  "ini": "ま",
  "name": "マイライフ・マイラブ ぼくの夢わたしの願い",
  "maker": "バンプレスト",
  "releaseDate": "1991/8/3",
  "price": "9600",
  "genre": "その他",
  "icNo": "0"
 },
 {
  "id": "830",
  "ini": "あ",
  "name": "アルマジロ",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1991/8/9",
  "price": "6900",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "831",
  "ini": "て",
  "name": "DE・BLOCK",
  "maker": "アテナ",
  "releaseDate": "1991/8/9",
  "price": "4980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "832",
  "ini": "と",
  "name": "ドキ！ドキ！遊園地 クレイジーランド大作戦",
  "maker": "バップ",
  "releaseDate": "1991/8/9",
  "price": "5800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "833",
  "ini": "と",
  "name": "ドラゴンウォーズ",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1991/8/9",
  "price": "6800",
  "genre": "RPG",
  "icNo": "50"
 },
 {
  "id": "834",
  "ini": "ひ",
  "name": "百の世界の物語",
  "maker": "アスク講談社",
  "releaseDate": "1991/8/9",
  "price": "6200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "835",
  "ini": "と",
  "name": "ドラゴンボールZⅡ 激神フリーザ!!",
  "maker": "バンダイ",
  "releaseDate": "1991/8/10",
  "price": "8200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "836",
  "ini": "え",
  "name": "A列車で行こう",
  "maker": "ポニーキャニオン",
  "releaseDate": "1991/8/21",
  "price": "7800",
  "genre": "シミュレーション",
  "icNo": "99"
 },
 {
  "id": "837",
  "ini": "く",
  "name": "クライシスフォース",
  "maker": "コナミ",
  "releaseDate": "1991/8/27",
  "price": "6000",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "838",
  "ini": "ろ",
  "name": "ロイヤルブラッド",
  "maker": "光栄",
  "releaseDate": "1991/8/29",
  "price": "9800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "839",
  "ini": "め",
  "name": "メタルスレイダーグローリー",
  "maker": "HAL研究所",
  "releaseDate": "1991/8/30",
  "price": "8900",
  "genre": "アドベンチャー",
  "icNo": "100"
 },
 {
  "id": "840",
  "ini": "か",
  "name": "快傑ヤンチャ丸2 からくりランド",
  "maker": "アイレム",
  "releaseDate": "1991/8/30",
  "price": "5800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "841",
  "ini": "う",
  "name": "ウエスタンキッズ",
  "maker": "ビスコ",
  "releaseDate": "1991/9/13",
  "price": "7800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "842",
  "ini": "て",
  "name": "絵描衛門",
  "maker": "アテナ",
  "releaseDate": "1991/9/13",
  "price": "9800",
  "genre": "その他",
  "icNo": "91"
 },
 {
  "id": "843",
  "ini": "と",
  "name": "ドラゴンズレア",
  "maker": "EPIC・ソニー",
  "releaseDate": "1991/9/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "844",
  "ini": "へ",
  "name": "へべれけ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/9/20",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "845",
  "ini": "ま",
  "name": "マリオオープンゴルフ",
  "maker": "任天堂",
  "releaseDate": "1991/9/20",
  "price": "6000",
  "genre": "スポーツ",
  "icNo": "60"
 },
 {
  "id": "846",
  "ini": "い",
  "name": "イースⅢ ワンダラーズ フロム イース",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/9/27",
  "price": "7500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "847",
  "ini": "す",
  "name": "スパルタンX2",
  "maker": "アイレム",
  "releaseDate": "1991/9/27",
  "price": "5500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "848",
  "ini": "は",
  "name": "バトルフォーミュラ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/9/27",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "849",
  "ini": "な",
  "name": "中嶋悟 F-1 HERO2",
  "maker": "バリエ",
  "releaseDate": "1991/9/27",
  "price": "5900",
  "genre": "レース",
  "icNo": "10"
 },
 {
  "id": "850",
  "ini": "ち",
  "name": "ちびまる子ちゃん うきうきショッピング",
  "maker": "ナムコ",
  "releaseDate": "1991/10/4",
  "price": "5800",
  "genre": "その他",
  "icNo": "94"
 },
 {
  "id": "851",
  "ini": "か",
  "name": "からくり剣豪伝 ムサシロード",
  "maker": "ユタカ",
  "releaseDate": "1991/10/5",
  "price": "6800",
  "genre": "RPG",
  "icNo": "54"
 },
 {
  "id": "852",
  "ini": "え",
  "name": "SDガンダム外伝 ナイトガンダム物語2 光の騎士",
  "maker": "バンダイ",
  "releaseDate": "1991/10/12",
  "price": "8200",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "853",
  "ini": "か",
  "name": "カオスワールド",
  "maker": "ナツメ",
  "releaseDate": "1991/10/25",
  "price": "7000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "854",
  "ini": "た",
  "name": "たいむゾーン",
  "maker": "シグマ商事",
  "releaseDate": "1991/10/25",
  "price": "6200",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "855",
  "ini": "く",
  "name": "グレートディール",
  "maker": "ヘクト",
  "releaseDate": "1991/10/25",
  "price": "6800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "856",
  "ini": "と",
  "name": "特救指令ソルブレイン",
  "maker": "エンジェル",
  "releaseDate": "1991/10/26",
  "price": "6000",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "857",
  "ini": "も",
  "name": "モノポリー",
  "maker": "トミー",
  "releaseDate": "1991/11/1",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "858",
  "ini": "す",
  "name": "スター・ウォーズ",
  "maker": "ビクター音楽産業",
  "releaseDate": "1991/11/15",
  "price": "6700",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "859",
  "ini": "ら",
  "name": "ラディア戦記 黎明篇",
  "maker": "テクモ",
  "releaseDate": "1991/11/15",
  "price": "6900",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "860",
  "ini": "し",
  "name": "少年アシベ ネパール大冒険の巻",
  "maker": "タカラ",
  "releaseDate": "1991/11/15",
  "price": "6980",
  "genre": "アドベンチャー",
  "icNo": "0"
 },
 {
  "id": "861",
  "ini": "た",
  "name": "大工の源さん",
  "maker": "アイレム",
  "releaseDate": "1991/11/15",
  "price": "6500",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "862",
  "ini": "ち",
  "name": "地底戦空バゾルダー",
  "maker": "ソフエル",
  "releaseDate": "1991/11/15",
  "price": "6300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "863",
  "ini": "は",
  "name": "パチ夫くん4",
  "maker": "ココナッツジャパン",
  "releaseDate": "1991/11/22",
  "price": "8800",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "864",
  "ini": "み",
  "name": "みんなのたあ坊のなかよし大作戦",
  "maker": "キャラクターソフト",
  "releaseDate": "1991/11/22",
  "price": "3980",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "865",
  "ini": "も",
  "name": "燃えプロ！最強編",
  "maker": "ジャレコ",
  "releaseDate": "1991/11/22",
  "price": "6700",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "866",
  "ini": "あ",
  "name": "アメリカ横断ウルトラクイズ 史上最大の戦い",
  "maker": "トミー",
  "releaseDate": "1991/11/29",
  "price": "7800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "867",
  "ini": "ふ",
  "name": "ファミコン 囲碁入門",
  "maker": "アイマックス",
  "releaseDate": "1991/11/29",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "868",
  "ini": "ら",
  "name": "ランパート",
  "maker": "コナミ",
  "releaseDate": "1991/11/29",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "869",
  "ini": "ふ",
  "name": "ファミコンジャンプⅡ 最強の７人",
  "maker": "バンダイ",
  "releaseDate": "1991/12/2",
  "price": "8500",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "870",
  "ini": "す",
  "name": "スノーブラザーズ",
  "maker": "東亜プラン",
  "releaseDate": "1991/12/6",
  "price": "6500",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "871",
  "ini": "ろ",
  "name": "ロックマン4 新たなる野望!!",
  "maker": "カプコン",
  "releaseDate": "1991/12/6",
  "price": "7800",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "872",
  "ini": "へ",
  "name": "平成 天才バカボン",
  "maker": "ナムコ",
  "releaseDate": "1991/12/6",
  "price": "5800",
  "genre": "アクション",
  "icNo": "94"
 },
 {
  "id": "873",
  "ini": "き",
  "name": "ギミア・ぶれいく 史上最強のクイズ王決定戦",
  "maker": "ヨネザワPR21",
  "releaseDate": "1991/12/13",
  "price": "9800",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "874",
  "ini": "つ",
  "name": "つるピカハゲ丸 めざせ！つるセコの証",
  "maker": "ジャレコ",
  "releaseDate": "1991/12/13",
  "price": "6300",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "875",
  "ini": "て",
  "name": "ティーンエイジ・ミュータント・ニンジャ・タートルズ2 ザ・マンハッタンプロジェクト",
  "maker": "コナミ",
  "releaseDate": "1991/12/13",
  "price": "6900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "876",
  "ini": "て",
  "name": "テクモスーパーボウル",
  "maker": "テクモ",
  "releaseDate": "1991/12/13",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "877",
  "ini": "て",
  "name": "テトリス2 +ボンブリス",
  "maker": "BPS",
  "releaseDate": "1991/12/13",
  "price": "6500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "878",
  "ini": "よ",
  "name": "ヨッシーのたまご",
  "maker": "任天堂",
  "releaseDate": "1991/12/14",
  "price": "4900",
  "genre": "パズル",
  "icNo": "62"
 },
 {
  "id": "879",
  "ini": "あ",
  "name": "アスミッくんランド",
  "maker": "アスミック",
  "releaseDate": "1991/12/20",
  "price": "6300",
  "genre": "アクション",
  "icNo": "21"
 },
 {
  "id": "880",
  "ini": "あ",
  "name": "アンタッチャブル",
  "maker": "アルトロン",
  "releaseDate": "1991/12/20",
  "price": "6800",
  "genre": "その他",
  "icNo": "2"
 },
 {
  "id": "881",
  "ini": "た",
  "name": "ダイナマイトバットマン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1991/12/20",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "882",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ",
  "maker": "コナミ",
  "releaseDate": "1991/12/20",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "883",
  "ini": "は",
  "name": "パーマンPART2 秘密結社マドー団をたおせ！",
  "maker": "アイレム",
  "releaseDate": "1991/12/20",
  "price": "5800",
  "genre": "アクション",
  "icNo": "22"
 },
 {
  "id": "884",
  "ini": "は",
  "name": "バトルトード",
  "maker": "日本コンピュータシステム",
  "releaseDate": "1991/12/20",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "885",
  "ini": "は",
  "name": "バナナン王子の大冒険",
  "maker": "タカラ",
  "releaseDate": "1991/12/20",
  "price": "5900",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "886",
  "ini": "ふ",
  "name": "ファミスタ'92",
  "maker": "ナムコ",
  "releaseDate": "1991/12/20",
  "price": "5000",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "887",
  "ini": "も",
  "name": "モンスターメーカー ７つの秘宝",
  "maker": "ソフエル",
  "releaseDate": "1991/12/20",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "888",
  "ini": "と",
  "name": "東京パチスロアドベンチャー",
  "maker": "ココナッツジャパン",
  "releaseDate": "1991/12/20",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "10"
 },
 {
  "id": "889",
  "ini": "は",
  "name": "爆笑 人生劇場3",
  "maker": "タイトー",
  "releaseDate": "1991/12/20",
  "price": "6400",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "890",
  "ini": "え",
  "name": "SDガンダム ガチャポン戦士4 ニュータイプストーリー",
  "maker": "ユタカ",
  "releaseDate": "1991/12/21",
  "price": "8500",
  "genre": "シミュレーション",
  "icNo": "42"
 },
 {
  "id": "891",
  "ini": "の",
  "name": "信長の野望 武将風雲録",
  "maker": "光栄",
  "releaseDate": "1991/12/21",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "892",
  "ini": "ち",
  "name": "鳥人戦隊ジェットマン",
  "maker": "エンジェル",
  "releaseDate": "1991/12/21",
  "price": "6000",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "893",
  "ini": "は",
  "name": "バトルストーム",
  "maker": "ヨネザワPR21",
  "releaseDate": "1991/12/21",
  "price": "5800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "894",
  "ini": "へ",
  "name": "ベスト競馬 ダービースタリオン",
  "maker": "アスキー",
  "releaseDate": "1991/12/21",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "895",
  "ini": "ち",
  "name": "チキチキマシン猛レース",
  "maker": "アトラス",
  "releaseDate": "1991/12/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "896",
  "ini": "さ",
  "name": "ザ・ブルーマリーン",
  "maker": "ホット・ビィ",
  "releaseDate": "1991/12/27",
  "price": "6300",
  "genre": "その他",
  "icNo": "53"
 },
 {
  "id": "897",
  "ini": "は",
  "name": "ハドソン・ホーク",
  "maker": "EPIC・ソニー",
  "releaseDate": "1991/12/27",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "898",
  "ini": "う",
  "name": "ウルトラマン倶楽部3 またまた出撃!!ウルトラ兄弟",
  "maker": "ユタカ",
  "releaseDate": "1991/12/29",
  "price": "7800",
  "genre": "RPG",
  "icNo": "20"
 },
 {
  "id": "899",
  "ini": "た",
  "name": "第2次スーパーロボット大戦",
  "maker": "バンプレスト",
  "releaseDate": "1991/12/29",
  "price": "8200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "900",
  "ini": "か",
  "name": "がんばれゴエモン外伝2 天下の財宝",
  "maker": "コナミ",
  "releaseDate": "1992/1/3",
  "price": "6980",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "901",
  "ini": "ひ",
  "name": "ピザポップ",
  "maker": "ジャレコ",
  "releaseDate": "1992/1/7",
  "price": "6300",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "902",
  "ini": "た",
  "name": "太陽の勇者ファイバード",
  "maker": "アイレム",
  "releaseDate": "1992/1/11",
  "price": "6800",
  "genre": "シューティング",
  "icNo": "30"
 },
 {
  "id": "903",
  "ini": "そ",
  "name": "ソロモンの鍵2 クールミン島救出作戦",
  "maker": "テクモ",
  "releaseDate": "1992/1/24",
  "price": "6200",
  "genre": "パズル",
  "icNo": "56"
 },
 {
  "id": "904",
  "ini": "ひ",
  "name": "ビジネスウォーズ 最強の企業戦略M&A",
  "maker": "ヘクト",
  "releaseDate": "1992/1/24",
  "price": "9700",
  "genre": "シミュレーション",
  "icNo": "2"
 },
 {
  "id": "905",
  "ini": "は",
  "name": "バーズテイルⅡ THE DESTINY KNIGHT",
  "maker": "ポニーキャニオン",
  "releaseDate": "1992/1/25",
  "price": "7000",
  "genre": "RPG",
  "icNo": "31"
 },
 {
  "id": "906",
  "ini": "し",
  "name": "将棋名鑑'92",
  "maker": "ヘクト",
  "releaseDate": "1992/1/30",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "907",
  "ini": "き",
  "name": "ギミック！",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/1/31",
  "price": "6200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "908",
  "ini": "と",
  "name": "ドンドコドン2",
  "maker": "タイトー",
  "releaseDate": "1992/1/31",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "909",
  "ini": "は",
  "name": "バッキー オヘア",
  "maker": "コナミ",
  "releaseDate": "1992/1/31",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "910",
  "ini": "え",
  "name": "F-1 サーカス",
  "maker": "日本物産",
  "releaseDate": "1992/2/7",
  "price": "6600",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "911",
  "ini": "い",
  "name": "いけいけ！熱血ホッケー部 『すべってころんで大乱闘』",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/2/7",
  "price": "6200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "912",
  "ini": "か",
  "name": "海戦ゲーム ネイビーブルー",
  "maker": "アイマックス",
  "releaseDate": "1992/2/14",
  "price": "5900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "913",
  "ini": "あ",
  "name": "Advanced Dungeons & Dragons ドラゴン・オブ・フレイム",
  "maker": "ポニーキャニオン",
  "releaseDate": "1992/2/21",
  "price": "6800",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "914",
  "ini": "は",
  "name": "パズロット",
  "maker": "サミー工業",
  "releaseDate": "1992/2/28",
  "price": "5600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "915",
  "ini": "ま",
  "name": "マジックキャンドル",
  "maker": "サミー工業",
  "releaseDate": "1992/3/6",
  "price": "7800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "916",
  "ini": "う",
  "name": "伝染るんです。 かわうそハワイへ行く",
  "maker": "タカラ",
  "releaseDate": "1992/3/6",
  "price": "6800",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "917",
  "ini": "い",
  "name": "囲碁指南'92",
  "maker": "ヘクト",
  "releaseDate": "1992/3/10",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "918",
  "ini": "な",
  "name": "ナムコクラシックⅡ",
  "maker": "ナムコ",
  "releaseDate": "1992/3/13",
  "price": "5900",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "919",
  "ini": "ふ",
  "name": "ファイアーエムブレム外伝",
  "maker": "任天堂",
  "releaseDate": "1992/3/14",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "920",
  "ini": "き",
  "name": "究極ハリキリ甲子園",
  "maker": "タイトー",
  "releaseDate": "1992/3/19",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "921",
  "ini": "す",
  "name": "SUPER桃太郎電鉄",
  "maker": "ハドソン",
  "releaseDate": "1992/3/20",
  "price": "5800",
  "genre": "その他",
  "icNo": "12"
 },
 {
  "id": "922",
  "ini": "そ",
  "name": "それいけ！アンパンマン みんなでハイキングゲーム！",
  "maker": "エンジェル",
  "releaseDate": "1992/3/20",
  "price": "6000",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "923",
  "ini": "ふ",
  "name": "Hook",
  "maker": "EPIC・ソニー",
  "releaseDate": "1992/3/27",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "924",
  "ini": "た",
  "name": "WWF レッスルマニアチャレンジ",
  "maker": "ホット・ビィ",
  "releaseDate": "1992/3/27",
  "price": "5300",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "925",
  "ini": "は",
  "name": "ハローキティワールド",
  "maker": "キャラクターソフト",
  "releaseDate": "1992/3/27",
  "price": "4800",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "926",
  "ini": "ふ",
  "name": "プラズマ・ボール",
  "maker": "ジャレコ",
  "releaseDate": "1992/3/27",
  "price": "6300",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "927",
  "ini": "ほ",
  "name": "炎の闘球児 ドッジ弾平",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/3/28",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "928",
  "ini": "ひ",
  "name": "ひらけ！ポンキッキ",
  "maker": "タカラ",
  "releaseDate": "1992/4/17",
  "price": "4980",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "929",
  "ini": "ま",
  "name": "舛添要一 朝までファミコン",
  "maker": "ココナッツジャパン",
  "releaseDate": "1992/4/17",
  "price": "7980",
  "genre": "アドベンチャー",
  "icNo": "42"
 },
 {
  "id": "930",
  "ini": "え",
  "name": "エキサイティングラリー",
  "maker": "科学技研",
  "releaseDate": "1992/4/24",
  "price": "5900",
  "genre": "レース",
  "icNo": "0"
 },
 {
  "id": "931",
  "ini": "わ",
  "name": "わんぱくコックンのグルメワールド",
  "maker": "タイトー",
  "releaseDate": "1992/4/24",
  "price": "5900",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "932",
  "ini": "し",
  "name": "重力装甲メタルストーム",
  "maker": "アイレム",
  "releaseDate": "1992/4/24",
  "price": "6500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "933",
  "ini": "ひ",
  "name": "ひょっこりひょうたん島 なぞのかいぞくせん",
  "maker": "ユタカ",
  "releaseDate": "1992/4/25",
  "price": "6000",
  "genre": "アドベンチャー",
  "icNo": "56"
 },
 {
  "id": "934",
  "ini": "ま",
  "name": "麻雀大戦",
  "maker": "日本物産",
  "releaseDate": "1992/5/20",
  "price": "6600",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "935",
  "ini": "く",
  "name": "クイズ プロジェクトQ",
  "maker": "ヘクト",
  "releaseDate": "1992/5/29",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "936",
  "ini": "か",
  "name": "CAPCOM バルセロナ'92",
  "maker": "カプコン",
  "releaseDate": "1992/6/5",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "63"
 },
 {
  "id": "937",
  "ini": "さ",
  "name": "三国志Ⅱ 覇王の大陸",
  "maker": "ナムコ",
  "releaseDate": "1992/6/10",
  "price": "6900",
  "genre": "シミュレーション",
  "icNo": "94"
 },
 {
  "id": "938",
  "ini": "ま",
  "name": "まじかる☆タルるートくん2 まほうだいぼうけん",
  "maker": "バンダイ",
  "releaseDate": "1992/6/19",
  "price": "5800",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "939",
  "ini": "え",
  "name": "エスパードリーム2 新たなる戦い",
  "maker": "コナミ",
  "releaseDate": "1992/6/26",
  "price": "6800",
  "genre": "RPG",
  "icNo": "91"
 },
 {
  "id": "940",
  "ini": "た",
  "name": "ターミネーター2",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/6/26",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "941",
  "ini": "ひ",
  "name": "びっくり熱血新記録！ はるかなる金メダル",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/6/26",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "942",
  "ini": "い",
  "name": "陰謀の惑星 シャンカラ",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1992/6/26",
  "price": "9700",
  "genre": "シミュレーション",
  "icNo": "3"
 },
 {
  "id": "943",
  "ini": "せ",
  "name": "聖鈴伝説リックル",
  "maker": "タイトー",
  "releaseDate": "1992/6/26",
  "price": "6800",
  "genre": "アクション",
  "icNo": "62"
 },
 {
  "id": "944",
  "ini": "さ",
  "name": "THE GOLF'92",
  "maker": "ジーオーワン",
  "releaseDate": "1992/7/3",
  "price": "6900",
  "genre": "スポーツ",
  "icNo": "20"
 },
 {
  "id": "945",
  "ini": "は",
  "name": "パチンコ大作戦2",
  "maker": "ココナッツジャパン",
  "releaseDate": "1992/7/10",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "43"
 },
 {
  "id": "946",
  "ini": "さ",
  "name": "Summer Carnical '92 烈火",
  "maker": "ナグザット",
  "releaseDate": "1992/7/17",
  "price": "4980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "947",
  "ini": "さ",
  "name": "サンリオカップ ポンポンバレー",
  "maker": "キャラクターソフト",
  "releaseDate": "1992/7/17",
  "price": "3980",
  "genre": "スポーツ",
  "icNo": "53"
 },
 {
  "id": "948",
  "ini": "れ",
  "name": "レッドアリーマーⅡ",
  "maker": "カプコン",
  "releaseDate": "1992/7/17",
  "price": "5800",
  "genre": "アクション",
  "icNo": "4"
 },
 {
  "id": "949",
  "ini": "み",
  "name": "三つ目がとおる",
  "maker": "トミー",
  "releaseDate": "1992/7/17",
  "price": "6500",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "950",
  "ini": "し",
  "name": "シルヴァ・サーガ",
  "maker": "セタ",
  "releaseDate": "1992/7/24",
  "price": "7800",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "951",
  "ini": "と",
  "name": "闘魂倶楽部",
  "maker": "ジャレコ",
  "releaseDate": "1992/7/24",
  "price": "6300",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "952",
  "ini": "ま",
  "name": "魔法のプリンセス ミンキーモモ リメンバードリーム",
  "maker": "ユタカ",
  "releaseDate": "1992/7/29",
  "price": "6000",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "953",
  "ini": "た",
  "name": "高橋名人の冒険島Ⅲ",
  "maker": "ハドソン",
  "releaseDate": "1992/7/31",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "954",
  "ini": "え",
  "name": "エイセスアイアンイーグルⅢ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1992/8/7",
  "price": "7400",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "955",
  "ini": "と",
  "name": "ドラゴンボールZⅢ 烈戦人造人間",
  "maker": "バンダイ",
  "releaseDate": "1992/8/7",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "956",
  "ini": "ふ",
  "name": "フリントストーン The Rescue OF Dino & Hoppy",
  "maker": "タイトー",
  "releaseDate": "1992/8/7",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "957",
  "ini": "む",
  "name": "ムーンクリスタル",
  "maker": "ヘクト",
  "releaseDate": "1992/8/28",
  "price": "7800",
  "genre": "アクション",
  "icNo": "2"
 },
 {
  "id": "958",
  "ini": "き",
  "name": "ギミア・ぶれいく 史上最強のクイズ王決定戦2",
  "maker": "ヨネザワ",
  "releaseDate": "1992/8/28",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "959",
  "ini": "た",
  "name": "ダービースタリオン 全国版",
  "maker": "アスキー",
  "releaseDate": "1992/8/29",
  "price": "7200",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "960",
  "ini": "す",
  "name": "スーパーマリオUSA",
  "maker": "任天堂",
  "releaseDate": "1992/9/14",
  "price": "4900",
  "genre": "アクション",
  "icNo": "13"
 },
 {
  "id": "961",
  "ini": "い",
  "name": "1999 　ほれ、みたことか！世紀末",
  "maker": "ココナッツジャパン",
  "releaseDate": "1992/9/18",
  "price": "7300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "962",
  "ini": "な",
  "name": "ナムコプリズムゾーン ドリームマスター",
  "maker": "ナムコ",
  "releaseDate": "1992/9/22",
  "price": "5800",
  "genre": "RPG",
  "icNo": "94"
 },
 {
  "id": "963",
  "ini": "こ",
  "name": "GOAL!!",
  "maker": "ジャレコ",
  "releaseDate": "1992/9/25",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "964",
  "ini": "き",
  "name": "キャプテンセイバー",
  "maker": "タイトー",
  "releaseDate": "1992/9/29",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "965",
  "ini": "み",
  "name": "ミッキーマウスⅢ 夢ふうせん",
  "maker": "ケムコ（コトブキシステム）",
  "releaseDate": "1992/9/30",
  "price": "5800",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "966",
  "ini": "し",
  "name": "シャッフルファイト",
  "maker": "バンプレスト",
  "releaseDate": "1992/10/9",
  "price": "7600",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "967",
  "ini": "す",
  "name": "SPOT",
  "maker": "BPS",
  "releaseDate": "1992/10/16",
  "price": "5500",
  "genre": "その他",
  "icNo": "10"
 },
 {
  "id": "968",
  "ini": "へ",
  "name": "ベストプレープロ野球 スペシャル",
  "maker": "アスキー",
  "releaseDate": "1992/10/16",
  "price": "7500",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "969",
  "ini": "と",
  "name": "トップストライカー",
  "maker": "ナムコ",
  "releaseDate": "1992/10/22",
  "price": "5800",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "970",
  "ini": "え",
  "name": "SDガンダム外伝 ナイトガンダム物語3 伝説の騎士団",
  "maker": "バンダイ",
  "releaseDate": "1992/10/23",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "971",
  "ini": "た",
  "name": "ダブルムーン伝説",
  "maker": "日本コンピュータシステム",
  "releaseDate": "1992/10/30",
  "price": "7900",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "972",
  "ini": "き",
  "name": "恐竜戦隊ジュウレンジャー",
  "maker": "エンジェル",
  "releaseDate": "1992/11/6",
  "price": "6000",
  "genre": "アクション",
  "icNo": "53"
 },
 {
  "id": "973",
  "ini": "と",
  "name": "トムとジェリー",
  "maker": "アルトロン",
  "releaseDate": "1992/11/13",
  "price": "6800",
  "genre": "アクション",
  "icNo": "50"
 },
 {
  "id": "974",
  "ini": "ふ",
  "name": "フェラーリ GRAND PRIX CHALLENGE",
  "maker": "ココナッツジャパン",
  "releaseDate": "1992/11/13",
  "price": "7300",
  "genre": "レース",
  "icNo": "10"
 },
 {
  "id": "975",
  "ini": "こ",
  "name": "コロンブス 黄金の夜明け",
  "maker": "トミー",
  "releaseDate": "1992/11/20",
  "price": "7500",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "976",
  "ini": "よ",
  "name": "ヨッシーのクッキー",
  "maker": "任天堂",
  "releaseDate": "1992/11/21",
  "price": "4900",
  "genre": "パズル",
  "icNo": "31"
 },
 {
  "id": "977",
  "ini": "た",
  "name": "タイニー・トゥーン アドベンチャーズ2 モンタナランドへようこそ",
  "maker": "コナミ",
  "releaseDate": "1992/11/27",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "978",
  "ini": "い",
  "name": "囲碁指南'93",
  "maker": "ヘクト",
  "releaseDate": "1992/11/27",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "979",
  "ini": "ち",
  "name": "ちいさなおばけ アッチコッチソッチ",
  "maker": "バップ",
  "releaseDate": "1992/12/4",
  "price": "6800",
  "genre": "パズル",
  "icNo": "55"
 },
 {
  "id": "980",
  "ini": "ろ",
  "name": "ロックマン5 ブルースの罠?",
  "maker": "カプコン",
  "releaseDate": "1992/12/4",
  "price": "7800",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "981",
  "ini": "し",
  "name": "将棋名鑑'93",
  "maker": "ヘクト",
  "releaseDate": "1992/12/4",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "2"
 },
 {
  "id": "982",
  "ini": "わ",
  "name": "ワギャンランド3",
  "maker": "ナムコ",
  "releaseDate": "1992/12/8",
  "price": "5800",
  "genre": "アクション",
  "icNo": "94"
 },
 {
  "id": "983",
  "ini": "き",
  "name": "キョロちゃんランド",
  "maker": "ヒロ",
  "releaseDate": "1992/12/11",
  "price": "5800",
  "genre": "アクション",
  "icNo": "33"
 },
 {
  "id": "984",
  "ini": "は",
  "name": "ハローキティのおはなばたけ",
  "maker": "キャラクターソフト",
  "releaseDate": "1992/12/11",
  "price": "3980",
  "genre": "アクション",
  "icNo": "0"
 },
 {
  "id": "985",
  "ini": "よ",
  "name": "妖精物語 ロッド・ランド",
  "maker": "ジャレコ",
  "releaseDate": "1992/12/11",
  "price": "6500",
  "genre": "アクション",
  "icNo": "91"
 },
 {
  "id": "986",
  "ini": "し",
  "name": "ジャストブリード",
  "maker": "エニックス",
  "releaseDate": "1992/12/15",
  "price": "9700",
  "genre": "RPG",
  "icNo": "90"
 },
 {
  "id": "987",
  "ini": "は",
  "name": "バーコードワールド",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1992/12/18",
  "price": "7200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "988",
  "ini": "え",
  "name": "SDガンダム ガチャポン戦士5 BATTLE OF UNIVERSAL CENTURY",
  "maker": "ユタカ",
  "releaseDate": "1992/12/22",
  "price": "6800",
  "genre": "シミュレーション",
  "icNo": "44"
 },
 {
  "id": "989",
  "ini": "ふ",
  "name": "ファミスタ'93",
  "maker": "ナムコ",
  "releaseDate": "1992/12/22",
  "price": "4900",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "990",
  "ini": "ね",
  "name": "熱血格闘伝説",
  "maker": "テクノスジャパン",
  "releaseDate": "1992/12/23",
  "price": "6500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "991",
  "ini": "う",
  "name": "ウルトラマン倶楽部 怪獣大決戦!!",
  "maker": "エンジェル",
  "releaseDate": "1992/12/25",
  "price": "6800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "992",
  "ini": "く",
  "name": "グレイトバトルサイバー",
  "maker": "バンプレスト",
  "releaseDate": "1992/12/25",
  "price": "7300",
  "genre": "アクション",
  "icNo": "51"
 },
 {
  "id": "993",
  "ini": "あ",
  "name": "嗚呼！野球人生 一直線",
  "maker": "サミー工業",
  "releaseDate": "1992/12/25",
  "price": "7300",
  "genre": "その他",
  "icNo": "53"
 },
 {
  "id": "994",
  "ini": "と",
  "name": "ドラゴンボールZ 激闘天下一武道会（データック）",
  "maker": "バンダイ",
  "releaseDate": "1992/12/29",
  "price": "7800",
  "genre": "アクション",
  "icNo": "92"
 },
 {
  "id": "995",
  "ini": "さ",
  "name": "サンリオカーニバル2",
  "maker": "キャラクターソフト",
  "releaseDate": "1993/1/14",
  "price": "3980",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "996",
  "ini": "わ",
  "name": "ワイリー&ライトのロックボード ザッツ☆パラダイス",
  "maker": "カプコン",
  "releaseDate": "1993/1/15",
  "price": "6500",
  "genre": "テーブル",
  "icNo": "55"
 },
 {
  "id": "997",
  "ini": "か",
  "name": "仮面ライダーSD グランショッカーの野望",
  "maker": "エンジェル",
  "releaseDate": "1993/1/22",
  "price": "6500",
  "genre": "その他",
  "icNo": "62"
 },
 {
  "id": "998",
  "ini": "え",
  "name": "F-1 SENSATION",
  "maker": "コナミ",
  "releaseDate": "1993/1/29",
  "price": "5800",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "999",
  "ini": "あ",
  "name": "悪魔城ドラキュラ",
  "maker": "コナミ",
  "releaseDate": "1993/2/5",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1000",
  "ini": "け",
  "name": "けろけろけろっぴの大冒険2 ドーナツ池はおおさわぎ！",
  "maker": "キャラクターソフト",
  "releaseDate": "1993/2/19",
  "price": "5400",
  "genre": "アクション",
  "icNo": "65"
 },
 {
  "id": "1001",
  "ini": "は",
  "name": "バトルベースボール",
  "maker": "バンプレスト",
  "releaseDate": "1993/2/19",
  "price": "8600",
  "genre": "スポーツ",
  "icNo": "0"
 },
 {
  "id": "1002",
  "ini": "は",
  "name": "バイオミラクル ぼくってウパ",
  "maker": "コナミ",
  "releaseDate": "1993/2/26",
  "price": "3900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1003",
  "ini": "は",
  "name": "バブルボブル2",
  "maker": "タイトー",
  "releaseDate": "1993/3/5",
  "price": "5900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1004",
  "ini": "め",
  "name": "めざせ！トッププロ グリーンに賭ける夢",
  "maker": "ジャレコ",
  "releaseDate": "1993/3/5",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1005",
  "ini": "ゆ",
  "name": "USA ICE HOCKEY in FC",
  "maker": "ジャレコ",
  "releaseDate": "1993/3/6",
  "price": "6300",
  "genre": "スポーツ",
  "icNo": "91"
 },
 {
  "id": "1006",
  "ini": "す",
  "name": "スター・ウォーズ 帝国の逆襲",
  "maker": "ビクター音楽産業",
  "releaseDate": "1993/3/12",
  "price": "7900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1007",
  "ini": "ひ",
  "name": "ぴょこたんの大迷路",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/3/19",
  "price": "4980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "1008",
  "ini": "か",
  "name": "カジノダービー &スーパービンゴ",
  "maker": "ヨネザワ",
  "releaseDate": "1993/3/19",
  "price": "5500",
  "genre": "その他",
  "icNo": "2"
 },
 {
  "id": "1009",
  "ini": "ほ",
  "name": "星のカービィ 夢の泉の物語",
  "maker": "任天堂",
  "releaseDate": "1993/3/23",
  "price": "6500",
  "genre": "アクション",
  "icNo": "12"
 },
 {
  "id": "1010",
  "ini": "あ",
  "name": "蒼き狼と白き牝鹿 元朝秘史",
  "maker": "光栄",
  "releaseDate": "1993/3/25",
  "price": "11800",
  "genre": "シミュレーション",
  "icNo": "91"
 },
 {
  "id": "1011",
  "ini": "も",
  "name": "もえろツインビー シナモン博士を救え！",
  "maker": "コナミ",
  "releaseDate": "1993/3/26",
  "price": "3900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "1012",
  "ini": "あ",
  "name": "愛先生のO・SHI・E・TE わたしの星",
  "maker": "アイレム",
  "releaseDate": "1993/3/26",
  "price": "12800",
  "genre": "その他",
  "icNo": "13"
 },
 {
  "id": "1013",
  "ini": "ほ",
  "name": "炎の闘球児 ドッジ弾平2",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1993/3/26",
  "price": "6800",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1014",
  "ini": "か",
  "name": "快傑ヤンチャ丸3 対決！ゾウリンゲン",
  "maker": "アイレム",
  "releaseDate": "1993/3/30",
  "price": "6500",
  "genre": "アクション",
  "icNo": "54"
 },
 {
  "id": "1015",
  "ini": "え",
  "name": "SDガンダム ガンダムウォーズ（データック]）",
  "maker": "バンダイ",
  "releaseDate": "1993/4/23",
  "price": "2800",
  "genre": "シミュレーション",
  "icNo": "92"
 },
 {
  "id": "1016",
  "ini": "う",
  "name": "ウルトラマン倶楽部 スポ根ファイト！（データック）",
  "maker": "バンダイ",
  "releaseDate": "1993/4/23",
  "price": "2800",
  "genre": "スポーツ",
  "icNo": "92"
 },
 {
  "id": "1017",
  "ini": "く",
  "name": "くにおくんの熱血サッカーリーグ",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/4/23",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1018",
  "ini": "し",
  "name": "ジェットソン COGSWELL'S CAPER！",
  "maker": "タイトー",
  "releaseDate": "1993/4/23",
  "price": "6800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "1019",
  "ini": "た",
  "name": "ダックテイルズ2",
  "maker": "カプコン",
  "releaseDate": "1993/4/23",
  "price": "5800",
  "genre": "アクション",
  "icNo": "42"
 },
 {
  "id": "1020",
  "ini": "こ",
  "name": "ゴリラーマン",
  "maker": "ヨネザワ",
  "releaseDate": "1993/4/28",
  "price": "6900",
  "genre": "その他",
  "icNo": "2"
 },
 {
  "id": "1021",
  "ini": "し",
  "name": "ジョイメカファイト",
  "maker": "任天堂",
  "releaseDate": "1993/5/21",
  "price": "4900",
  "genre": "アクション",
  "icNo": "44"
 },
 {
  "id": "1022",
  "ini": "は",
  "name": "パチ夫くん5 Jrの挑戦",
  "maker": "ココナッツジャパン",
  "releaseDate": "1993/6/18",
  "price": "8500",
  "genre": "テーブル",
  "icNo": "30"
 },
 {
  "id": "1023",
  "ini": "ま",
  "name": "マイティファイナルファイト",
  "maker": "カプコン",
  "releaseDate": "1993/6/11",
  "price": "6500",
  "genre": "アクション",
  "icNo": "10"
 },
 {
  "id": "1024",
  "ini": "し",
  "name": "Ｊリーグ ファイティングサッカー THE KING OF ACE STRIKERS",
  "maker": "アイ・ジー・エス",
  "releaseDate": "1993/6/19",
  "price": "7900",
  "genre": "スポーツ",
  "icNo": "61"
 },
 {
  "id": "1025",
  "ini": "う",
  "name": "うしおととら 深淵の大妖",
  "maker": "ユタカ",
  "releaseDate": "1993/7/9",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "1026",
  "ini": "ふ",
  "name": "ぷよぷよ",
  "maker": "徳間書店",
  "releaseDate": "1993/7/23",
  "price": "5900",
  "genre": "パズル",
  "icNo": "0"
 },
 {
  "id": "1027",
  "ini": "ふ",
  "name": "FC原人 FREAKTHOROPUS COMPUTERUS",
  "maker": "ハドソン",
  "releaseDate": "1993/7/30",
  "price": "5800",
  "genre": "アクション",
  "icNo": "31"
 },
 {
  "id": "1028",
  "ini": "こ",
  "name": "甲竜伝説ヴィルガスト外伝",
  "maker": "エンジェル",
  "releaseDate": "1993/7/30",
  "price": "8000",
  "genre": "RPG",
  "icNo": "0"
 },
 {
  "id": "1029",
  "ini": "と",
  "name": "ドラゴンボールZ外伝 サイヤ人絶滅計画",
  "maker": "バンダイ",
  "releaseDate": "1993/8/6",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "1030",
  "ini": "く",
  "name": "クレヨンしんちゃん おらとポイポイ（データック）",
  "maker": "バンダイ",
  "releaseDate": "1993/8/27",
  "price": "2600",
  "genre": "パズル",
  "icNo": "92"
 },
 {
  "id": "1031",
  "ini": "く",
  "name": "クレヨンしんちゃん おらとポイポイ",
  "maker": "バンダイ",
  "releaseDate": "1993/8/27",
  "price": "5800",
  "genre": "パズル",
  "icNo": "2"
 },
 {
  "id": "1032",
  "ini": "は",
  "name": "パチスロアドベンチャー2 そろっ太くんのパチスロ探偵団",
  "maker": "ココナッツジャパン",
  "releaseDate": "1993/9/17",
  "price": "6900",
  "genre": "テーブル",
  "icNo": "21"
 },
 {
  "id": "1033",
  "ini": "て",
  "name": "テトリスフラッシュ",
  "maker": "任天堂",
  "releaseDate": "1993/9/21",
  "price": "5900",
  "genre": "パズル",
  "icNo": "10"
 },
 {
  "id": "1034",
  "ini": "ゆ",
  "name": "幽☆遊☆白書 爆闘暗黒武術会（データック）",
  "maker": "バンダイ",
  "releaseDate": "1993/10/22",
  "price": "2800",
  "genre": "アクション",
  "icNo": "92"
 },
 {
  "id": "1035",
  "ini": "た",
  "name": "大工の源さん2 赤毛のダンの逆襲",
  "maker": "アイレム",
  "releaseDate": "1993/10/22",
  "price": "6800",
  "genre": "アクション",
  "icNo": "62"
 },
 {
  "id": "1036",
  "ini": "ろ",
  "name": "ろくでなしBLUES",
  "maker": "バンダイ",
  "releaseDate": "1993/10/29",
  "price": "7800",
  "genre": "RPG",
  "icNo": "4"
 },
 {
  "id": "1037",
  "ini": "ろ",
  "name": "ロックマン6 史上最大の闘い!!",
  "maker": "カプコン",
  "releaseDate": "1993/11/5",
  "price": "7800",
  "genre": "アクション",
  "icNo": "30"
 },
 {
  "id": "1038",
  "ini": "は",
  "name": "バトルラッシュ BUILD UP ROBOT TOURNAMENT（データック）",
  "maker": "ビーアイ",
  "releaseDate": "1993/11/13",
  "price": "2800",
  "genre": "アクション",
  "icNo": "92"
 },
 {
  "id": "1039",
  "ini": "あ",
  "name": "RPG人生ゲーム",
  "maker": "タカラ",
  "releaseDate": "1993/11/26",
  "price": "7800",
  "genre": "その他",
  "icNo": "30"
 },
 {
  "id": "1040",
  "ini": "け",
  "name": "けろっぴとけろりーぬのスプラッシュボム！",
  "maker": "キャラクターソフト",
  "releaseDate": "1993/12/1",
  "price": "4980",
  "genre": "アクション",
  "icNo": "56"
 },
 {
  "id": "1041",
  "ini": "ふ",
  "name": "ファミスタ'94",
  "maker": "ナムコ",
  "releaseDate": "1993/12/1",
  "price": "3900",
  "genre": "スポーツ",
  "icNo": "94"
 },
 {
  "id": "1042",
  "ini": "ち",
  "name": "チップとデールの大作戦2",
  "maker": "カプコン",
  "releaseDate": "1993/12/10",
  "price": "5800",
  "genre": "アクション",
  "icNo": "55"
 },
 {
  "id": "1043",
  "ini": "な",
  "name": "なかよしといっしょ",
  "maker": "ユタカ",
  "releaseDate": "1993/12/10",
  "price": "7800",
  "genre": "アドベンチャー",
  "icNo": "12"
 },
 {
  "id": "1044",
  "ini": "い",
  "name": "囲碁指南'94",
  "maker": "ヘクト",
  "releaseDate": "1993/12/17",
  "price": "6800",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1045",
  "ini": "も",
  "name": "桃太郎伝説外伝",
  "maker": "ハドソン",
  "releaseDate": "1993/12/17",
  "price": "6800",
  "genre": "RPG",
  "icNo": "52"
 },
 {
  "id": "1046",
  "ini": "ね",
  "name": "熱血！すとりーとバスケット がんばれ Dunk Heroes",
  "maker": "テクノスジャパン",
  "releaseDate": "1993/12/17",
  "price": "6500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "1047",
  "ini": "せ",
  "name": "ゼルダの伝説1",
  "maker": "任天堂",
  "releaseDate": "1994/2/19",
  "price": "4900",
  "genre": "アドベンチャー",
  "icNo": "54"
 },
 {
  "id": "1048",
  "ini": "わ",
  "name": "ワリオの森",
  "maker": "任天堂",
  "releaseDate": "1994/2/19",
  "price": "4900",
  "genre": "パズル",
  "icNo": "64"
 },
 {
  "id": "1049",
  "ini": "ふ",
  "name": "ファイナルファンタジーⅠ・Ⅱ",
  "maker": "スクウェア",
  "releaseDate": "1994/2/27",
  "price": "6800",
  "genre": "RPG",
  "icNo": "90"
 },
 {
  "id": "1050",
  "ini": "し",
  "name": "Jリーグ スーパートッププレイヤーズ（データック）",
  "maker": "バンダイ",
  "releaseDate": "1994/4/22",
  "price": "2800",
  "genre": "スポーツ",
  "icNo": "92"
 },
 {
  "id": "1051",
  "ini": "は",
  "name": "パチスロアドベンチャー3 ビタオシー7見参！",
  "maker": "ココナッツジャパン",
  "releaseDate": "1994/5/13",
  "price": "7900",
  "genre": "テーブル",
  "icNo": "0"
 },
 {
  "id": "1052",
  "ini": "し",
  "name": "Ｊリーグ ウイニングゴール",
  "maker": "エレクトロニック・アーツ・ビクター",
  "releaseDate": "1994/5/27",
  "price": "7980",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "1053",
  "ini": "た",
  "name": "高橋名人の冒険島Ⅳ",
  "maker": "ハドソン",
  "releaseDate": "1994/6/24",
  "price": "5800",
  "genre": "アクション",
  "icNo": "1"
 }
];